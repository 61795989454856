import Template from './_template.js';
import {initConsView} from './_ae-textviewer-content-cons.js';
import {initDiplView} from './_ae-textviewer-content-dipl.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';
import 'tify';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';


export default class AeTextviewerContent extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeTextviewerContent.connectedCallback() ...");

    // Create unique id for this component
    this._componentId = "component-" + parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(10).toString().replace(".", ""));

    // Store lang parameter
    this._lang = aeI18n.getLang();

    // Prepare properties object
    const props = {
      view: this.getAttribute('view'),
    };
    
    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');

    // Load data, if a text resource is specified and has not been loaded yet
    if (this.textResource && (this.textResource != this._loadedTextResource)) {
      this._loadData(this.textResource);
    }

    // Add event listeners
    eventBusStd.register('textviewer.apparatus.annotation.show', (evt) => this._onAnnotationShow(evt));
    eventBusStd.register('textviewer.apparatus.annotation.remove', (evt) => this._onAnnotationRemove(evt));
    eventBusStd.register('textviewer.apparatus.gotoLemma', (evt) => this._onGotoLemma(evt));
    eventBusStd.register('textviewer.apparatus.close', (evt) => this._onApparatusClose(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['mode', 'text-resource', 'view'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeTextviewerContent.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'mode':
        if (this._dom) {
          this._content = this._initializeView(this.view);
        }
        break;
      case 'text-resource':
        if (this.textResource && (this.textResource != this._loadedTextResource)) {
          this._loadData(newVal);
        }
        break;
      case 'view':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
    }
  }


  // *** Getters / Setters ***

  set idref(val) {
    this.setAttribute("idref", val);
  }

  get idref() {
    return this.getAttribute("idref");
  }
  
  set mode(val) {
    this.setAttribute("mode", val);
  }

  get mode() {
    return this.getAttribute("mode");
  }

  set textResource(val) {
    this.setAttribute("text-resource", val);
  }

  get textResource() {
    return this.getAttribute("text-resource");
  }

  set textVersion(val) {
    this.setAttribute("text-version", val);
  }

  get textVersion() {
    return this.getAttribute("text-version");
  }

  set view(val) {
    this.setAttribute("view", val);
  }

  get view() {
    return this.getAttribute("view");
  }

  set views(val) {
    this.setAttribute("views", val);
  }

  get views() {
    return this.getAttribute("views");
  }


  // *** Methods ***

  _loadData(textResource) {

    console.log("(DEMO) AeTextviewerContent._loadData() ...");


    // Remove templates
    let templatesOld = this.querySelectorAll('template');
    for (let i = 0; i < templatesOld.length; i++) {
      templatesOld[i].remove();
    }
    
    // Available views
    const views = this.views.split(",");


    // --- Load content for each template ---

    for (let i = 0; i < views.length; i++) {

      let requestURL = new URL(window.location.origin + window.location.pathname + "/~query/modules/ae/query/renderText.xq");
      requestURL.searchParams.set('ae-id', textResource);
      requestURL.searchParams.set('view', views[i]);

      let xhr = new XMLHttpRequest();
      xhr.open("GET", requestURL, true);
      xhr.send();

      xhr.onload = () => {
        if (xhr.status != 200) { 
          // Analyze HTTP status of the response
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { 
          // Show the result
          // console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
        
          // DEBUG
          // console.log(xhr.response);

          // Prepare properties object
          let props = {
            content: xhr.response,
            view: views[i]
          }

          // Append data
          this.insertAdjacentHTML('afterbegin', Template.html(props));

          // Render only current view
          if (views[i] === this.view) {
            this._content = this._render(this.view);
          }
        }
      };

      xhr.onprogress = function(event) {
        if (event.lengthComputable) {
          // console.log(`Received ${event.loaded} of ${event.total} bytes`);
        } else {
          // console.log(`Received ${event.loaded} bytes`); // No content length
        }
      };

      xhr.onerror = function() {
        console.log("Request failed");
      };
    }


    // Save name of resource currently loaded to variable. This variable can be used to prevent double loading.
    this._loadedTextResource = textResource;
  };


  _render(view) {

    console.log("(DEMO) AeTextviewerContent._render() ...");

    // Default template (@data-view="cons")
    if (!view) {
      view = "cons";
    }

    // Check if template is available
    if (this.querySelector('template[data-view=' + view + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        console.log("(DEBUG) Component is populated, removing content ...");
        this._content.remove();
      }
      if (this.querySelector('style')) {
        this.querySelector('style').remove();
      }
      // Render component DOM
      this.appendChild(this.querySelector('template[data-view=' + view + ']').content.cloneNode(true));
      this.insertAdjacentHTML('afterbegin', '<style>' + Template.css({view: view}) + '</style>');
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize component size and view
    return this._initializeView(view);
  }


  _initializeView(view) {

    console.log("(DEMO) AeTextviewerContent._initializeView() ...");
    

    // --- Initialize style ---

    // Scroll to top
    this.scrollTo(0,0);

    // Clear event listeners
    window.removeEventListener("resize", () => {this._fitContentToViewport()});

    // Mode 'synopsis'
    if (this.mode === "synopsis") {

      // Add event listener to window
      window.addEventListener("resize", () => {this._fitContentToViewport()});

      // Initialize content size
      this._fitContentToViewport();
    }


    // --- Initialize view ---

    // Disconnect former ResizeObserver
    if (this._ro) {
      this._ro.disconnect();
    }

    switch (view) {
      case "cons":
        initConsView(this, this._dom.wrapper, this._dom);
        break;
      case "dipl":
        initDiplView(this);
        break;
      case "facs":
        // TODO: Outsource to separate JS
        if (this._dom.wrapper.querySelector("#iiif-viewer").getAttribute("data-iiif-manifest").length) {
          this._initFacsViewTify(this._dom.wrapper.querySelector("#iiif-viewer").getAttribute("data-iiif-manifest"));
        } else {
          this._initFacsView();
        }
        break;
      case "code":
        break;
      default:
        break;
    }
    

    // --- Initialize index popovers ---

    this._initIndexPopovers("person");

    
    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  // TODO: Outsource to separate JS
  _initFacsView() {

    console.log("(DEMO): AeTextviewerContent._initFacsView() ...");

    // Make id of image viewer container unique
    const idImageViewer = "openseadragon_" + this._componentId;
    this._dom.wrapper.querySelector("#iiif-viewer").setAttribute("id", idImageViewer);
    
    // Build requests to info.json files
    let imgs = JSON.parse(this.querySelector('#facsimile-uri-array').getAttribute("data-uri-array"));
    let urls = imgs.map(img => img["url"]);
    let requests = urls.map(url => fetch(url));
    
    // WIP: Some CORS header stuff necessary?
    /* let requests = urls.map(url => fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Sec-Fetch-Site": "cross-site",
          "Sec-Fetch-Mode": "no-cors",
        }
      })); */

    // Get session id
    const sid = urls[0].slice(urls[0].indexOf('sid=') + 4).substring(0, urls[0].slice(urls[0].indexOf('sid=') + 4).indexOf("/"));

    // Get info.json files
    Promise.all(requests)
      .then((responses) => {

        let imgObjsPrepped = [];

        Promise.all(responses.map(r => r.json()))
          .then((r) => {
            // Add sid to JSON objects
            r.forEach(
              (r) => {
                r["@id"] = r["@id"] + ";sid=" + sid;
                imgObjsPrepped.push(r);
              }
            )
          })
          .then((r) => {
            // Initialize viewer
            this._initIIIFViewer(idImageViewer, imgObjsPrepped);
          });
      });
  }


  _initFacsViewTify(manifestUrl) {

    console.log("(DEMO): AeTextviewerContent._initFacsViewTify() ...");

    // Make id of image viewer container unique
    const idImageViewer = "tify_" + this._componentId;
    this._dom.wrapper.querySelector("#iiif-viewer").setAttribute("id", idImageViewer);
    
    new Tify({
      container: '#' + idImageViewer,
      manifestUrl: manifestUrl,
      language: this._lang,
      translationsDirUrl: window.location.pathname + '/~assets/v2/js/ae-i18n/tify',
    })
  }


  // TODO: Outsource to separate JS
  //
  // OPTIONS that lead to strnage rendering rsults:
  // referenceStripPosition: 'TOP_LEFT',
  // referenceStripScroll: 'vertical',
  //
  // NOTE: unsure about: crossOriginPolicy: 'Anonymous',
  _initIIIFViewer(id, tileSources) {
    OpenSeadragon({
      id: id,
      prefixUrl: "~assets/openseadragon/images/",
      sequenceMode: true,
      showReferenceStrip: true,
      tileSources: tileSources,
      immediateRender: true,
      imageLoaderLimit: 10,
    });
  }


  /*
   * Fit height of ae-textviewer-content/wrapper to viewport
   * starting from offsetTop of ae-textviewer-content. 
   */
  _fitContentToViewport() {
    this.style.height = (document.documentElement.clientHeight - this.getBoundingClientRect().top) + "px";
  }

  
  /*
   * Initialize click event listener for popovers of a given type
   */
  _initIndexPopovers(type) {

    // Add event listener for wrapper
    this._dom.wrapper.addEventListener("click", (evt) => {
      const item = evt.target.closest('[data-type="' + type + '"]');
      
      // If there is a target and target's data-type attribute matches given type...
      if (item && item.dataset.type === type) {

        // Create custom event
        let ce = new CustomEvent("popover.toggle", {
          detail: {
            scope: this._dom.wrapper,
            boundary: this,
            type: "index." + type,
            item: item
          }
        });

        // Hand over custom event directly instead of dispatching event (due to performance)
        const popover = this._dom.wrapper.querySelector('[popover-id="' + item.getAttribute('data-popover-ref') + '"]');
        if (popover) {
          popover._onPopoverToggle(ce);
        };
      };
    });
  }


  /*
   * Hide all displayed popovers of a given type
   */
  _hidePopoversOfType(type) {

    // Get all displayed popovers
    const popovers = this._dom.wrapper.querySelectorAll('ae-popover[show="true"][type="' + type + '"]');

    // Hide popovers via component property
    if (popovers) {
      for (let i = 0; i < popovers.length; i++) {
        popovers[i].show = "false";
      }
    };
  }

  /*
   * Set focus on lemma
   */
  _onAnnotationShow(evt) {

    // Only react, when emitted by own apparatus
    if (evt.detail.viewer === this.closest("ae-textviewer")) {

      // Remove current focus
      const focussedLemma = this._dom.wrapper.querySelector(".lemmaHighlight.focus");
      if (focussedLemma) {
        focussedLemma.classList.remove("focus");
      }

      // Set focus
      this._dom.wrapper.querySelector(".lemmaHighlight[data-ae-anno-id-ref='#" + evt.detail.id + "']").classList.add("focus");
    }
  }

  /*
   * Remove focus and highlighting from lemma
   */
  _onAnnotationRemove(evt) {

    // Only react, when emitted by own apparatus
    if (evt.detail.viewer === this.closest("ae-textviewer")) {

      // Remove current focus
      const lemma = this._dom.wrapper.querySelector(".lemmaHighlight[data-ae-anno-id-ref='#" + evt.detail.id + "']");
      if (lemma) {
        lemma.classList.remove("lemHighNorm", "active", "focus");
      }
    }
  }


  /*
   * Scroll lemma into view
   */
  _onGotoLemma(evt) {
  
    // Only react, when emitted by own apparatus
    if (evt.detail.viewer === this.closest("ae-textviewer")) {

      // Scroll into view
      const lemma = this._dom.wrapper.querySelector(".lemmaHighlight[data-ae-anno-id-ref='#" + evt.detail.id + "']");
      if (lemma) {
        lemma.scrollIntoView({behavior: "smooth", block: "center"});
      }
    }
  }


  /*
   * Remove all lemma highlightings
   */
  _onApparatusClose(evt) {

    // Only react, when emitted by own apparatus
    if (evt.detail.viewer === this.closest("ae-textviewer")) {

      // Get all highlighted lemmata
      const highlightedLemmata = this._dom.wrapper.querySelectorAll(".lemHighNorm");
      
      // Remove all classes related to highlighting and activation
      if (highlightedLemmata) {
        for (let i = 0; i < highlightedLemmata.length; i++) {
          highlightedLemmata[i].classList.remove("lemHighNorm", "active", "focus");
        }
      };
    }
  }


  /*
   * Toggle highlighting for a given index type
   */
  toggleIndexHighlighting(indexType, state) {
    console.log("ae-textviewer-content; toggleIndexHighlighting(); indexType = " + indexType + "; state = " + state);

    // TODO: Make this work for any given type
    if (state === "on") {
      for (let i = 0; i < this._dom.personElements.length; i++) {
        this._dom.personElements[i].classList.add("highlighted");
      }
    } else {
      for (let i = 0; i < this._dom.personElements.length; i++) {
        this._dom.personElements[i].classList.remove("highlighted");
      }
    }
  }

  /*
   * Toggle popovers for a given index type
   */
  toggleIndexPopovers(indexType, state) {
    console.log("ae-textviewer-content; toggleIndexPopovers(); indexType = " + indexType + "; state = " + state);

    // TODO: Make this work for any given type
    if (state === "on") {
      // Enable popovers for indexed content
      for (let i = 0; i < this._dom.personElements.length; i++) {
        this._dom.personElements[i].setAttribute("data-popover", "enabled");
      }
    } else {
      // Hide popovers that are currently displayed
      this._hidePopoversOfType("index." + indexType);
      // Disable popovers for indexed content
      for (let i = 0; i < this._dom.personElements.length; i++) {
        this._dom.personElements[i].setAttribute("data-popover", "disabled");
      }
    }
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-textviewer-content")) {
  customElements.define("ae-textviewer-content", AeTextviewerContent);
}