import styleText from './_style.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeIndexListing / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      header: scope.querySelector('.ae-index-listing-header'),
      content: scope.querySelector('.ae-index-listing-content')
    }
  },

  // Templates
  html(p) {
    return `
      <template data-layout="index-page">
        <div class="wrapper">
          <ae-index-listing-header class="ae-index-listing-header" layout="default"> </ae-index-listing-header>
          <ae-index-listing-content class="ae-index-listing-content" layout="default" data-source="${p.dataSource}"> </ae-index-listing-content>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  }
}