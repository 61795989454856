import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeButtonTab extends HTMLElement {

  constructor() {
    super();

    this._sR = this.attachShadow({mode: 'open'});

    // Properties
    const props = {
      key: this.getAttribute('n'),
      state: this.getAttribute('state'),
      type: this.getAttribute('type'),
      title: this.getAttribute('title'),
      add: this.getAttribute('add'),
      sub: this.getAttribute('sub'),
      ed: this.getAttribute('ed'),
      label: this.getAttribute('label'),
    };

    // Load and append style and templates
    const style = document.createElement('style');
    style.innerHTML = Template.css(props);
    this._sR.appendChild(style);
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component depending on @layout
    this._content = this._render(this.layout);

    // Add event listeners
    this._sR.addEventListener('click', (evt) => {this._select();});
    eventBusStd.register('button-tab_select_event', (evt) => this._deselect(evt));

    // Forward own events to eventBus
    this.addEventListener('button-tab_select_event', (evt) => {
      if (evt.target === this) {eventBusStd.fire(evt)}
    });
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout', 'PROPERTY'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    switch(name) {
      case 'layout':
        this._content = this._render(newVal);
        break;
      case 'PROPERTY':
        this._DOSOMETHING(newVal);
        break;
    }
  }

  
  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set key(v) {
    this.setAttribute("key", v);
  }

  get key() {
    return this.getAttribute("key");
  }

  set state(v) {
    this.setAttribute("state", v);
  }

  get state() {
    return this.getAttribute("state");
  }

  set type(v) {
    this.setAttribute("type", v);
  }

  get type() {
    return this.getAttribute("type");
  }

  set title(v) {
    this.setAttribute("title", v);
  }

  get title() {
    return this.getAttribute("title");
  }

  set add(v) {
    this.setAttribute("add", v);
  }

  get add() {
    return this.getAttribute("add");
  }

  set sub(v) {
    this.setAttribute("sub", v);
  }

  get sub() {
    return this.getAttribute("sub");
  }

  set ed(v) {
    this.setAttribute("ed", v);
  }

  get ed() {
    return this.getAttribute("ed");
  }

  set label(v) {
    this.setAttribute("label", v);
  }

  get label() {
    return this.getAttribute("label");
  }


  
  // *** Methods ***

  _render(layout) {
    // Default template (@data-layout)
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this._sR.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this._sR);

    return this._dom.wrapper;
  }

  _select() {
    this.state = "active";

    let ce = new CustomEvent("button-tab_select_event", {
      // Remove bubbles when state change is communicated properly by wrapping index listing component
      bubbles: true,
      detail: {
        key: this.key,
      }
    });
    eventBusStd.fire(ce);

    // Dispatch for page controller to change URL
    // Remove also when state change is communicated properly by wrapping index listing component
    this.dispatchEvent(ce);
  }

  _deselect(evt) {
    if (!(evt.detail.key === this.key)) {
      this.state = "";
    }
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-button-tab")) {
  customElements.define("ae-button-tab", AeButtonTab);
}