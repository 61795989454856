import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeIndexListingContent / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      indexList: scope.querySelector('#ae-index-listing-content__list')
    }
  },

  html(p) {

    console.log("(DEMO) AeIndexListingContent / Template.html() ...");

    return `
      <template data-layout="${p.layout}">
        <div class="wrapper">
          <div id="ae-index-listing-content__list">

          </div>
        </div>
      </template>
    `;
  },

  renderIndexEntries(scope, data, filter) {

    // Array of entries
    let resultData = data.persons.person;

    // Filter array
    if (!(filter.page === "all")) {
      switch (_lang) {
        case 'de':
          resultData = data.persons.person.filter(item => this.resolveUmlauts(item.label.reg.de).startsWith(filter.page));
          break;
        case 'en':
          resultData = data.persons.person.filter(item => item.label.reg.en.startsWith(filter.page));
          break;
        default:
          resultData = data.persons.person.filter(item => item.label.generic.startsWith(filter.page));
      }
    }

    // Sort data depending on current language
    switch (_lang) {
      case 'de':
        resultData.sort((a, b) => a.label.reg.de.localeCompare(b.label.reg.de));
        break;
      case 'en':
        resultData.sort((a, b) => a.label.reg.en.localeCompare(b.label.reg.en));
        break;
      default:
        resultData.sort((a, b) => a.label.generic.localeCompare(b.label.generic));
    }

    // Remove existing items
    if (scope.querySelector('ae-index-listing-item')) {
      scope.textContent = '';
    }
    
    for (let i = 0; i < resultData.length; i++) {

      // Only render, if entry meets the following criteria
      // - status is not "wip" or "candidate_vol"
      // - role is not "editor" or "dummy"
      if (!(resultData[i]['status'] === "wip") && !(resultData[i]['status'] === "candidate_vol") && !(resultData[i]['role'] === "editor") && !(resultData[i]['role'] === "dummy")) {
        const item = document.createElement('ae-index-listing-item');

        // Get predicates
        let predicateType = resultData[i]['type'];
      
        let predicateVols = [];
        if (resultData[i]['vols'][0] === "0") {
          predicateVols[0] = resultData[i]['vols'];
        } else {
          for (let j = 0; j < resultData[i]['vols'].length; j++) {
            predicateVols.push(resultData[i]['vols'][j]);
          };
        }

        let predicateRole = resultData[i]['role'];

        const attrClass = document.createAttribute("class");
        attrClass.value = "ae-index-listing-item";
        const attrLayout = document.createAttribute("layout");
        attrLayout.value = "default";
        const attrIdRef = document.createAttribute("idref");
        attrIdRef.value = resultData[i]['id'];
        const attrLabel = document.createAttribute("label");
        switch(_lang) {
          case 'de':
            attrLabel.value = resultData[i]['label']['reg']['de'];
            break;
          case 'en':
            attrLabel.value = resultData[i]['label']['reg']['en'];
            break;
          default:
            attrLabel.value = resultData[i]['label']['generic'];
        }
        const attrPredicates = document.createAttribute("predicates");
        attrPredicates.value = "type=" + predicateType + ";role=" + predicateRole + ";vols=" + predicateVols;
        const attrGndRef = document.createAttribute("gnd");
        attrGndRef.value = resultData[i]['authority']['gnd'];

        item.setAttributeNode(attrClass);
        item.setAttributeNode(attrLayout);
        item.setAttributeNode(attrIdRef);
        item.setAttributeNode(attrLabel);
        item.setAttributeNode(attrPredicates);
        item.setAttributeNode(attrGndRef);

        scope.append(item);
      }
    };

  },

  css(p) {
    return `
      ${styleText}
    `;
  },

  resolveUmlauts(value){
    value = value.toLowerCase();
    value = value.replace(/ä/g, 'ae');
    value = value.replace(/ö/g, 'oe');
    value = value.replace(/ü/g, 'ue');
    /* value = value.replace(/ß/g, 'ss');
    value = value.replace(/ /g, '-');
    value = value.replace(/\./g, '');
    value = value.replace(/,/g, '');
    value = value.replace(/\(/g, '');
    value = value.replace(/\)/g, ''); */
    return value.toUpperCase();
  }
}