import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeIndexListingHeader / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
    }
  },

  // Templates
  html(p) {
    return `
    <template data-layout="default">
      <div class="wrapper">
        <!-- AE-INDEX-LISTING-HEADER -->
        <div class="ae-btn-grp">
          <ae-button-tab layout="default" key="A" label="A" state="${(p.page === 'A') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="B" label="B" state="${(p.page === 'B') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="C" label="C" state="${(p.page === 'C') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="D" label="D" state="${(p.page === 'D') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="E" label="E" state="${(p.page === 'E') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="F" label="F" state="${(p.page === 'F') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="G" label="G" state="${(p.page === 'G') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="H" label="H" state="${(p.page === 'H') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="I" label="I" state="${(p.page === 'I') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="J" label="J" state="${(p.page === 'J') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="K" label="K" state="${(p.page === 'K') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="L" label="L" state="${(p.page === 'L') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="M" label="M" state="${(p.page === 'M') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="N" label="N" state="${(p.page === 'N') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="O" label="O" state="${(p.page === 'O') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="P" label="P" state="${(p.page === 'P') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="Q" label="Q" state="${(p.page === 'Q') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="R" label="R" state="${(p.page === 'R') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="S" label="S" state="${(p.page === 'S') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="T" label="T" state="${(p.page === 'T') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="U" label="U" state="${(p.page === 'U') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="V" label="V" state="${(p.page === 'V') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="W" label="W" state="${(p.page === 'W') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="X" label="X" state="${(p.page === 'X') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="Y" label="Y" state="${(p.page === 'Y') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="Z" label="Z" state="${(p.page === 'Z') ? 'active' : ''}"> </ae-button-tab>
          <ae-button-tab layout="default" key="all" label="${aeI18n.translate("ui.label.all", _lang)}" state="${(p.page === 'all') ? 'active' : ''}"> </ae-button-tab>
          <span class="filler"> </span>
        </div>
      </div>
    </template>
      `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  }
}