import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeTocTextgroup extends HTMLElement {

  connectedCallback() {

    const props = {
      n: this.getAttribute('n'),
      state: this.getAttribute('state'),
    };
    
    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');

    // Render component depending on @layout
    this._content = this._render(this.layout);

    // Add event listeners
    eventBusStd.register('textgroup_select_event', (evt) => this._onSelectionChange(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout', 'PROPERTY'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
      case 'PROPERTY':
        this._DOSOMETHING(newVal);
        break;
    }
  }

  
  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set n(v) {
    this.setAttribute("n", v);
  }

  get n() {
    return this.getAttribute("n");
  }

  set state(v) {
    this.setAttribute("state", v);
  }

  get state() {
    return this.getAttribute("state");
  }



  
  // *** Methods ***

  _render(layout) {
    // Default template (@data-layout)
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Fill "custom slots"
    this._fillSlots();

    return this._dom.wrapper;
  }

  _fillSlots() {
    // iterate over slot elements and fill with data, if present
    for (let i = 0; i < this._dom.slots.length; i++) {
      let slotName = this._dom.slots[i].getAttribute('name');
      this._dom.slots[i].innerHTML = this.querySelectorAll('[data-slot="' + slotName + '"]')[0].innerHTML;
    }
  }

  _onSelectionChange(evt) {
    if (evt.detail.n === this.n) {
      this.state = "active";
    } else {
      this.state = "";
    }
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-toc-textgroup")) {
  customElements.define("ae-toc-textgroup", AeTocTextgroup);
}