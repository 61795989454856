import styleText_AeButton from '../../../scss/ae-components/elements/_ae-button.scss';
const styleElement_AeButton = document.createElement('style');
styleElement_AeButton.appendChild(document.createTextNode(styleText_AeButton));


const template_AeButton = document.createElement('template');

template_AeButton.innerHTML = styleElement_AeButton.outerHTML + `
  <div class="ae-button">
    <button>Label</button>
  </div>
`;
  
class AeButton extends HTMLElement {
    
  constructor() {
    super();

    this._shadowRoot = this.attachShadow({mode: 'open'});
    this._shadowRoot.appendChild(template_AeButton.content.cloneNode(true));

    this.$container = this._shadowRoot.querySelector('.ae-button');
    this.$button = this._shadowRoot.querySelector('button');
  
    // this exposes the custom event 'on-click' as API to the outside
    // to add event, use:
    //  document.querySelector('ae-button').addEventListener('ae-click', (evt) => console.log("Clicked! Event detail: " + evt.detail))
    this.$button.addEventListener("click", () => {
      this.dispatchEvent(
        new CustomEvent('ae-click', {
          detail: 'Hello from within ae-button!',
        })
      );
    });
  }
  
  connectedCallback() {
    if (this.hasAttribute('as-atom')) {
      this.$container.style.padding = '0px';
    }
  }
  
  // getters/setters for reflecting properties to custom element attributes
  get label() {
    return this.getAttribute('label');
  }
  
  set label(value) {
    this.setAttribute('label', value);
  }
  
  get test() {
    return this.getAttribute('test');
  }
  
  set test(value) {
    this.setAttribute('test', value);
  }
  
  static get observedAttributes() {
    return ['label', 'test'];
  }
  
  attributeChangedCallback(name, oldValue, newValue) {
    this.render();
  }
  
  render() {
    this.$button.innerHTML = this.label;
  }
}


if (!customElements.get('ae-button')) {
  customElements.define('ae-button', AeButton);
}


export default AeButton;