/* 
 * Functions specific to text view "diplomatic"
 */

export {initDiplView};


/* 
 * initConsView()
 *
 * Interface for text view "cons"
 * 
 * @param c    Instance of component "ae-textviewer-content"
 *
 */
function initDiplView(c) {

  console.log("(DEMO) AeTextviewerContent / initDiplView() ...");

  // Add event listeners for @class='interactive_bracketAltDel'
  let elems_bracketAltDel = c.querySelectorAll(".interactive_bracketAltDel");
  for (let i = 0; i < elems_bracketAltDel.length; i++) {
    elems_bracketAltDel[i].addEventListener("mouseover", (evt) => _bracketAltDelOn(elems_bracketAltDel[i], c));
    elems_bracketAltDel[i].addEventListener("mouseout", (evt) => _bracketAltDelOff(elems_bracketAltDel[i]));
  }

  // Add event listeners for @class='interactive_transposition'
  let elems_transposition = c.querySelectorAll(".interactive_transposition");
  for (let i = 0; i < elems_transposition.length; i++) {
    if (elems_transposition[i].closest(".modSuperImp") === null) {                                                      //IK: Transpositions that are in a <mod> element need to be redirected here.
            elems_transposition[i].addEventListener("mouseover", (evt) => _transConnect(elems_transposition[i]));
            elems_transposition[i].addEventListener("mouseout", (evt) => _transDisConnect(elems_transposition[i]));
    } else {
            elems_transposition[i].closest(".modBackground").addEventListener("mouseover", (evt) => _transConnect(elems_transposition[i]));
            elems_transposition[i].closest(".modBackground").addEventListener("mouseout", (evt) => _transDisConnect(elems_transposition[i].closest(".modBackground")));
    }             
  }

  // Add event listeners for @class='interactive_showOrig'
  let elems_showOrig = c.querySelectorAll(".interactive_showOrig");
  for (let i = 0; i < elems_showOrig.length; i++) {
    elems_showOrig[i].addEventListener("mouseover", (evt) => _showOrig(elems_showOrig[i]));
  }

  // Add event listeners for @class='interactive_floatAdd'
  let elems_floatAdd = c.querySelectorAll(".interactive_floatAdd");
  for (let i = 0; i < elems_floatAdd.length; i++) {
    elems_floatAdd[i].addEventListener("mouseover", (evt) => {
        if (elems_floatAdd[i].closest('.modBackground') === null) {     //IK: if "float" is in a <mod> (overwritten characters) trigger the <mod> mouseover here, since it is at the same place and mouseover is already taken by the "float"
            evt.stopPropagation();
            _floatAddOn(elems_floatAdd[i]);
         } else {
            evt.stopPropagation();
            _floatAddOn(elems_floatAdd[i]);
            _showOrig(elems_floatAdd[i].closest(".modBackground"));
         } 
    });
    elems_floatAdd[i].addEventListener("mouseout", (evt) => _floatAddOff(elems_floatAdd[i]));
  }

  // Add event listeners for @class='interactive_floatAddMirrored'
  let elems_floatAddMirrored = c.querySelectorAll(".interactive_floatAddMirrored");
  for (let i = 0; i < elems_floatAddMirrored.length; i++) {
    elems_floatAddMirrored[i].addEventListener("mouseover", (evt) => _floatAddOnMirrored(elems_floatAddMirrored[i]));
    elems_floatAddMirrored[i].addEventListener("mouseout", (evt) => _floatAddOffMirrored(elems_floatAddMirrored[i]));
  }

  //IK: Align all attached notes to left margin of current page. 
  if (c.querySelector(".pages") != null) {realignAttachedNotes(c);}  
    
  //IK: frames for multi-line transpositons
  if (c.querySelector(".pages") != null) {initMultiLineTranspositionFrames(c);}  

  // Initialize scaling
  if (c.querySelector("#masterPage")) {_initScaling(c);}

  // If this component resides within BS modal: Initialize IntersectionObserver
  if (c.closest(".tab-pane__div--dipl")) {
    c._onDisplay = false;
    _initVisibilityObserver(c);
  }

  // If property 'idref' is set, scroll to id position
  if (c.idref) {
    const elemTarget = c.querySelector('#' + c.idref);
    _scrollToTarget(c, elemTarget);
  }
}


/* _scrollToTarget(e)
 *
 * Bring a given target element nicely into view
 * 
 * @param c    Component instance
 * @param e    The target element
 */
function _scrollToTarget(c, e) {
  // TODO: This component should not need to check what is going on outside!
  const navHeight = document.querySelector('ae-navbar nav') ? document.querySelector('ae-navbar nav').getBoundingClientRect().height : 0;
  const textviewerHeaderHeight = c.previousElementSibling ? c.previousElementSibling.getBoundingClientRect().height : 0;
  const offset = navHeight + textviewerHeaderHeight;

  // What to scroll depends on mode
  switch (c.mode) {
    case "default":
      window.scrollTo(0, e.getBoundingClientRect().top - offset);
    case "synopsis":
      c.scrollTo(0, e.getBoundingClientRect().top - offset);
      break;
    default:
      // do nothing
      break;
  }  
}


/* _initVisibilityObserver(c)
 *
 * When textviewers are appended to the DOM without being in 
 * within the current viewport, scaling content to component will fail.
 * This function initializes a VisibilityObserver to react on 
 * components coming into view.
 * 
 * TODO: Function is independent from view; move to a generic JS.
 *
 * @param c    Component instance
 */
function _initVisibilityObserver(c) {

  // Disconnect former IntersectionObserver
  if (c._io) {
    c._io.disconnect();
  }

  // IntersectionObserver
  c._io = new IntersectionObserver(entries => {
    for (let entry of entries) {
      if (c._onDisplay) {
        // Component was on display before and is already initialized
        // DEBUG console.log("IntersectionObserver: Component was on display before and is already initialized");
        // DEBUG console.log(entry);
      } else {
        if (entry.isIntersecting) {
          // First appearance, initialize scaling
          // DEBUG console.log("IntersectionObserver: First appearance, initialize scaling");
          // DEBUG console.log(entry);
          c._onDisplay = true;
          _initScaling(c);
        }
      }
    }
  });

  // Observe component container
  c._io.observe(c);
}


/* _initScaling(c)
 *
 * Initialize a ResizeObserver for the component that allows to fit
 * content to component boundaries when component size changes.
 * 
 * TODO: Deal with margins?
 *
 * @param c    Component instance
 */
function _initScaling(c) {

  // Disconnect former ResizeObserver
  if (c._ro) {
    c._ro.disconnect();
  }
  
  // Get initial component width
  const contentWidthInitial = c.querySelector("#masterPage").getBoundingClientRect().width;
  
  // Get initial component width
  const componentWidthInitial = c.getBoundingClientRect().width;

  // Fit content to component
  const fitFactor = contentWidthInitial / componentWidthInitial;
  c.querySelector("#masterPage").style.transform = "scale(" + (1 / fitFactor) + ")";

  // ResizeObserver
  // Get target element via entry.target
  // Get dimensions via .width, .height., .top, .left
  c._ro = new ResizeObserver(entries => {
    for (let entry of entries) {
      const cr = entry.contentRect;
      _resizeComponentContent(c, cr.width, contentWidthInitial);
    }
  });

  // Observe component container
  c._ro.observe(c);
}


/* 
 * _resizeComponentContent(c, componentWidthCurrent, contentWidthInitial)
 *
 * Resize component content by scaling via CSS:
 * If component width is lower than component content width
 * then scale content down.
 * 
 * @param c    Component instance
 * @param componentWidthCurrent    The current width value
 * @param contentWidthInitial    A reference width value (typically the initial width of the component content)
 */
function _resizeComponentContent(c, componentWidthCurrent, contentWidthInitial) {
  const scaleFactor = ((componentWidthCurrent / contentWidthInitial) > 1) ? 1 : (componentWidthCurrent / contentWidthInitial);
  c.querySelector("#masterPage").style.transform = "scale(" + scaleFactor + ")";
}


//IK: frames for multi-line transpositons
function initMultiLineTranspositionFrames(currentPage) {
    let actDipl = currentPage.querySelectorAll(".pages");
    let transList = currentPage.querySelectorAll(".transMultiline");
    transList.forEach(makeFrame);
    function makeFrame(curElem) {
        let listElem = curElem.children;
        let firstLeft = curElem.children[0].offsetLeft -5;                                              //left position of first line 
        let firstTop = (() => {if ( curElem.classList.contains("transIsSingle") ) {return curElem.children[0].offsetTop-3;} else {return curElem.children[0].offsetTop-10;} })(); //top position of first line 
        let pageLeft = actDipl[0].style.paddingLeft.slice(0,actDipl[0].style.paddingLeft.length - 2);   //left position of page
        let firstLeftGap = firstLeft - pageLeft;                                                        //gap between left side of page and left side of the beginning of the transposition
        let lastTop = listElem[listElem.length - 1].offsetTop;                                          //top position of last line (if both are identical then transposition is on one line only)
        let lastBottom = lastTop +22;
        let lastRight = listElem[listElem.length - 1].offsetLeft + listElem[listElem.length - 1].offsetWidth;   //right position of last line of transposition
        let lHeight = (() => {if ( curElem.classList.contains("transIsSingle") ) {return 25} else {return 35} })();
        //Check whether left position of the beginning of the transposition is at the left position of the page, or not.
        //If not, an additional vertical line is needed.
        let frameLeftY = (() => {if ( firstLeft-pageLeft < 5 ) {return "V"+firstTop;} else {return "V"+(firstTop+lHeight)+" H"+firstLeft+" V"+firstTop;}})();
        let frameRightX = (curElem.offsetLeft+curElem.offsetWidth) - firstLeftGap;                              //rightmost position of transposition on page
        let frameRightY, frameBottom;
        //if last line is longest than this line is reight bottom y, otherwise its last line - 22px (one line less)
        if ( Math.abs(lastRight-frameRightX) < 10 ) {
            frameRightY = lastBottom;
            frameBottom = "H"+(pageLeft-5);                                                             //in this case the horizontal bottom line can go through to the left of the transposition / page
        } else {
            frameRightY = lastBottom - lHeight; 
            frameBottom = "H"+(lastRight+2)+" V"+(lastTop + 25)+" H"+(pageLeft-5);
        }
        let frameTopHorizontal = "H"+frameRightX;
        let frameRightVertical = "V"+frameRightY;
        let pathSVG = (() => {
            if (curElem.closest(".atNoteIn") != null) {
                return curElem.closest(".atNoteIn");
              } else if (curElem.closest(".mouseover_glue") != null) {
                return curElem.closest(".mouseover_glue > span");
              } else {
                return actDipl[0];
              }
        })();
        let strokeColor = (() => {if (curElem.classList.contains("delTrans")) {return "rgba(128, 128, 128, 0.8)";} else {return "rgba(55, 55, 255,0.8)"}})();
        let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        let pathElem = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        svgElem.setAttribute("height", "100%");
        svgElem.setAttribute("width", "100%");
        svgElem.setAttribute("class", "transpositionFrame interactive_transposition");
        pathElem.setAttribute("d", "M"+firstLeft+" "+firstTop+" "+frameTopHorizontal+" "+frameRightVertical+" "+frameBottom+" "+frameLeftY);    //draw frame
        pathElem.setAttribute("stroke-dasharray", "5,5");
        pathElem.setAttribute("stroke", strokeColor);
        pathElem.setAttribute("fill", "transparent");
        svgElem.append(pathElem);
        //Check whether in a two line transposition the beginning is right from the end part. If so draw only a simple frame.
        if ( curElem.classList.contains("trans2Lines") && firstLeft > lastRight ) {   
            curElem.classList.remove("transMultiline");
        } else {
            pathSVG.prepend(svgElem);       //place frame into dom
        }
    };
}


//IK: realign embedded attached notes
function realignAttachedNotes(currentPage){
  let actDipl = currentPage.querySelectorAll(".pages");
  let pageLeft = actDipl[0] ? actDipl[0].getBoundingClientRect().left : null;            
  let notesList = currentPage.querySelectorAll(".pages .atNoteIn");
  for (let i = 0; i < notesList.length; i++) {
        if (notesList[i].closest(".atNoteIn").length) {                                                             //nested notes should not be repositioned
          notesList[i].mouseover(function(e) {e.stopPropagation();});
      } else {
        let curNoteLeft = notesList[i].getBoundingClientRect().left;
        notesList[i].style.left = -curNoteLeft+pageLeft+"px";
        notesList[i].addEventListener("mouseover",(e) => e.stopPropagation());                                      //stop mouseover from travelling to parent elements, e.g. if container is nested in a "float"
        notesList[i].addEventListener("mouseover",(e) => notesList[i].closest(".floatadd").style.zIndex="999");     //make sure that attached notes embedded in floatadd show up on top (see protoIdeology p. 20) 
        notesList[i].addEventListener("mouseout",(e) => notesList[i].closest(".floatadd").style.zIndex="15");  
      }
    }
}


//mouseover for alternative deletions: highlight 
function _bracketAltDelOn(currentElem, currentPage) {
  //case: delSpan
  var delSpanID, listDelSpan;
  if ( currentElem.nextElementSibling !== null && currentElem.nextElementSibling.classList.contains("altDelID") && currentElem.nextElementSibling.hasAttribute('id') ) {
      delSpanID = currentElem.nextElementSibling.getAttribute('id').split('-')[1];
      listDelSpan = currentPage.querySelectorAll('.'+delSpanID+',.inSpan-'+delSpanID);
      if ( $('#anchorSpan-'+delSpanID).next().children().hasClass("bracketAltDel") || $('#anchorSpan-'+delSpanID).next().hasClass("modAltDelOuter") ) {}    //check whether delSpan structure is complete
          else {alert("Something is missing! Check <delSpan/> structure in the TEI document.");}
      $(listDelSpan).css("color", "#2D3B83");
      if ( $(currentElem).find(".modBackground").length || $(currentElem).find(".interLineAdd2").length 
           || $(currentElem).find(".floatadd").length ) {} else {$(currentElem).children().addClass("bracketAltDelHover");}               //if bracket is within a <mod> element (superimposed), don't change size & color
      if ( $('#anchorSpan-'+delSpanID).next().hasClass("modAltDelOuter") ) {}
          else {$('#anchorSpan-'+delSpanID).next().children().addClass("bracketAltDelHover");}
  } else if ( $(currentElem).prev().hasClass("altDelID") & $(currentElem).prev().is('[id]') ) {
      delSpanID = $(currentElem).prev()[0].getAttribute('id').split('-')[1];
      listDelSpan = $('.'+delSpanID+',.inSpan-'+delSpanID);
      if ( $('#delSpan-'+delSpanID).prev().children().hasClass("bracketAltDel") || $('#delSpan-'+delSpanID).prev().hasClass("modAltDelOuter") ) {} 
          else {alert("Something is missing! Check <delSpan/> structure in the TEI document.");}
      $(listDelSpan).css("color", "#2D3B83");
      if ( $(currentElem).find(".modBackground").length || $(currentElem).find(".interLineAdd2").length 
           || $(currentElem).find(".floatadd").length ) {} else {$(currentElem).children().addClass("bracketAltDelHover");}               //if bracket is within a <mod> element (superimposed), don't change size & color
      if ( $('#delSpan-'+delSpanID).prev().hasClass("modAltDelOuter") ) {}
           else {$('#delSpan-'+delSpanID).prev().children().addClass("bracketAltDelHover");}             
  //case: del @type='altDeletion'   
  } else if ( $(currentElem).next().hasClass("altDeletion") & 
              ( $( currentElem ).next().next().hasClass("bracketAltDelOuter") || $( currentElem ).next().next().hasClass("modAltDelOuter") ) ) {
      $( currentElem ).nextUntil(".bracketAltDelOuter, .modAltDelOuter").css("color", "#2D3B83");
      if ($(currentElem).next().next().hasClass("modAltDelOuter")) {} else {$( currentElem ).nextAll(".bracketAltDelOuter").eq(0).children().addClass("bracketAltDelHover");}
      if ( $(currentElem).find(".modBackground").length || $(currentElem).find(".interLineAdd2").length 
           || $(currentElem).find(".floatadd").length ) {} else {$( currentElem ).children().addClass("bracketAltDelHover");}               //if bracket is within a <mod> element (superimposed), don't change size & color
  } else if ( $(currentElem).prev().hasClass("altDeletion") & 
              ( $(currentElem).prev().prev().hasClass("bracketAltDelOuter") || $(currentElem).prev().prev().hasClass("modAltDelOuter") ) ) {
      $( currentElem ).prevUntil(".bracketAltDelOuter, .modAltDelOuter").css("color", "#2D3B83");
      if ($(currentElem).prev().prev().hasClass("modAltDelOuter")) {} else {$( currentElem ).prevAll(".bracketAltDelOuter").eq(0).children().addClass("bracketAltDelHover");}
      if ( $(currentElem).find(".modBackground").length || $(currentElem).find(".interLineAdd2").length
           || $(currentElem).find(".floatadd").length ) {} else {$( currentElem ).children().addClass("bracketAltDelHover");}               //if bracket is within a <mod> element (superimposed), don't change size & color
  }
}


//mouseover for alternative deletions: hide highlight
function _bracketAltDelOff(currentElem) {
  var delSpanID, listDelSpan, delSpanEnv;
  //case: delSpan
  if ( $(currentElem).next().hasClass("altDelID") & $(currentElem).next().is('[id]') ) {
      delSpanID = $(currentElem).next()[0].getAttribute('id').split('-')[1];
      listDelSpan = $('.'+delSpanID+',.inSpan-'+delSpanID);
      //if user hovers over altDel text
      if ( checkHover() === true ) {
          delSpanHover()
      } else {revertChanges();}
  } else if ( $(currentElem).prev().hasClass("altDelID") & $(currentElem).prev().is('[id]') ) {
      delSpanID = $(currentElem).prev()[0].getAttribute('id').split('-')[1];
      listDelSpan = $('.'+delSpanID+',.inSpan-'+delSpanID);
      //if user hovers over altDel text
      if ( checkHover() === true ) {
          delSpanHover()
      } else {revertChanges();}
  }
  function revertChanges() {
      $(listDelSpan).css("color", "black"); 
      $(".bracketAltDelHover").removeClass("bracketAltDelHover");
      $(".gapSymbol").css("color", "red");
  }
  function delSpanHover() {
      $.each(listDelSpan, function() {
          this.addEventListener("mouseleave", offDelSpan);                    //add mouse leave to all elements in delSpan sequence
          function offDelSpan() {                                             //if mouse leaves one of the elements: check for 
              if ( checkHover() === true ) {} else {                          //hover event on all of the elements again
                  revertChanges();                                            //if there is no hover on any of the elements
                  $.each(listDelSpan, function() {                            //revert changes and remove mouseleave
                      this.removeEventListener("mouseleave", offDelSpan);
                  })
              }
          }    
      })
  }
  //check all elements in listDelSpan whether any of them have hover event
  function checkHover() {
      var check = [];
      var i;
      for (i = 0; i < listDelSpan.length; i++) {
                  if ( $(listDelSpan[i]).is(':hover') ) {
                      check[i] = true;
                  } else {check[i] = false;}
              }
              if ( check.indexOf(true) === -1)  {
                  return false
              } else {
                  return true
              }
  }
  //case: del @type='altDeletion'
  if ( $( currentElem ).next().hasClass("altDeletion") & 
       ( $( currentElem ).next().next().hasClass("bracketAltDelOuter") || $( currentElem ).next().next().hasClass("modAltDelOuter") )   ) {               //check also if starting bracket is within <mod>
      if ( $( currentElem ).next().is(':hover') ) {
              $( currentElem ).next().mouseleave(function(){
              $( currentElem ).nextUntil(".bracketAltDelOuter").css("color", "black");
              $( currentElem ).nextAll(".bracketAltDelOuter").eq(0).children().removeClass("bracketAltDelHover"); 
              $( currentElem ).children().removeClass("bracketAltDelHover");
          });
          } else {
              $( currentElem ).nextUntil(".bracketAltDelOuter").css("color", "black");
              $( currentElem ).nextAll(".bracketAltDelOuter").eq(0).children().removeClass("bracketAltDelHover"); 
              $( currentElem ).children().removeClass("bracketAltDelHover");
          }
  } else if ( $( currentElem ).prev().hasClass("altDeletion") & 
              ( $( currentElem ).prev().prev().hasClass("bracketAltDelOuter") || $( currentElem ).prev().prev().hasClass("modAltDelOuter") )   ) {        //check also if starting bracket is within <mod>
      if ( $( currentElem ).prev().is(':hover') ) {
              $( currentElem ).prev().mouseleave(function(){
              $( currentElem ).prevUntil(".bracketAltDelOuter").css("color", "black");
              $( currentElem ).prevAll(".bracketAltDelOuter").eq(0).children().removeClass("bracketAltDelHover"); 
              $( currentElem ).children().removeClass("bracketAltDelHover");
          }); 
          } else {
              $( currentElem ).prevUntil(".bracketAltDelOuter").css("color", "black");
              $( currentElem ).prevAll(".bracketAltDelOuter").eq(0).children().removeClass("bracketAltDelHover"); 
              $( currentElem ).children().removeClass("bracketAltDelHover");
  }
  } 
}


// Draw line between transpositon marker and transposition contents
function _transConnect(currentElem) {
  var currentMasterPage;
  if ( $( currentElem ).parents(".demoPageDipl").length === 1 ) {
      currentMasterPage = $( currentElem ).parents(".demoPageDipl")[0];
  } else {
      currentMasterPage = $( currentElem ).parents(".pages")[0];
  }
  //prepare IDs for transposition objects
  var ElemID = $( currentElem ).prop("id").substring(11, $( currentElem ).prop("id").length);
  var ElemID2 = $( currentElem ).prop("id");
  $(".transpositionLine").stop(true, true).fadeOut();                                                                    //turn off fadeOut event should it still run
  $( "#transConnect"+ElemID2 ).each(function(){                                                                          //remove any extra elements that might accumulate when user moves very fast across mouseovers
          if ( $(this).css('display') == 'none' ) {$(this).remove();}
      });
      var trans1   //icon
      var trans2   //text box
      var firstLeft, lastRight, firstTop, lastTop, trans1Top, trans1Left, trans1Height, trans2Height;
      var scaleX = (currentMasterPage.getBoundingClientRect().width / currentMasterPage.offsetWidth);                   //get scale value which has to be taken into account in order to get correct position values
      //check whether transposition icon or text box is nested 
      if ( document.querySelector("#transIconID" + ElemID).parentNode.classList.contains("modSuperImp") === true ) {
        trans1 = document.getElementById("transIconID" + ElemID).closest(".modBackground");
        trans1Top = ($( trans1 ).closest(".modBackground").position().top / scaleX);
        trans1Left = $( trans1 ).closest(".modBackground").position().left / scaleX;
        trans1Height = document.getElementById("transIconID" + ElemID).getBoundingClientRect().height / scaleX;
      }
      else if ( document.querySelector("#transIconID" + ElemID).parentNode.classList.contains("interLineAdd2") === true ) {
        trans1 = document.getElementById("transIconID" + ElemID).closest(".interLineAdd2");
        trans1Top = ($( trans1 ).closest(".interLineAdd1").position().top / scaleX) - 15;
        trans1Left = $( trans1 ).closest(".interLineAdd1").position().left / scaleX;
        trans1Height = document.getElementById("transIconID" + ElemID).getBoundingClientRect().height / scaleX;
      } else {
        trans1 = document.getElementById("transIconID" + ElemID);
        trans1Top = $( trans1 ).position().top / scaleX;
        trans1Left = $( trans1 ).position().left / scaleX;
        trans1Height = trans1.getBoundingClientRect().height / scaleX;
      }
      if ( document.querySelector("#transContID" + ElemID).parentNode.classList.contains("interLineAdd2") === true ) {    //change into parameter
        //transpositions in above line additions (for now: only one-liners)
        trans2 = document.getElementById("transContID" + ElemID).closest(".interLineAdd2");
        firstLeft = $( trans2 ).closest(".interLineAdd1").position().left / scaleX  
        lastRight = firstLeft + $( trans2 )[0].getBoundingClientRect().width / scaleX;
        firstTop = ($( trans2 ).closest(".interLineAdd1").position().top / scaleX) - 18;
        lastTop = firstTop;
        trans2Height = trans2.getBoundingClientRect().height / scaleX -25;
      } else {
        //multi-line transpositions
        trans2 = document.getElementById("transContID" + ElemID);
        var listTrans = trans2.children;
        firstLeft = $(listTrans[0]).position().left / scaleX;
        lastRight = ($(listTrans[listTrans.length - 1]).position().left / scaleX) + listTrans[listTrans.length - 1].getBoundingClientRect().width / scaleX;
        firstTop = $(listTrans[0]).position().top / scaleX;                           //top position of first line 
        lastTop = ($(listTrans[listTrans.length - 1]).position().top / scaleX)        //top position of last line (if both are identical then transposition is on one line only)
        trans2Height = trans2.getBoundingClientRect().height / scaleX;
      }
      //get values on dimension and position of involved objects
      var trans1Width = trans1.getBoundingClientRect().width / scaleX;
      var trans2Width = trans2.getBoundingClientRect().width / scaleX;
      var trans1MiddleX = trans1Left + (trans1Width / 2);
      var trans1MiddleY = trans1Top + (trans1Height / 2);
      var trans2MiddleX = firstLeft + (trans2Width / 2);
      var trans2MiddleY = firstTop + (trans2Height / 2);
      var trans2X = 0;
      var trans2Y = 0;
      var trans1X = trans1MiddleX;                                                                      //final position for trans1 (icon): X
      var trans1Y = 0;
      let yTopCorr = (() => {if ( firstTop < lastTop ) {return 8} else {return 0} })();                 //y correction for top of multi-line transpositions
      let actDipl = document.querySelectorAll(".pages");
      let pageLeft = actDipl[0].style.paddingLeft.slice(0,actDipl[0].style.paddingLeft.length - 2);     //left position of page
      //final position for trans2 (text box): X
      if ( firstTop < lastTop & trans1Top < trans2MiddleY & trans1MiddleX < firstLeft ) {trans2X = firstLeft;}                                          //multi-line transp., icon on top and left from beginning of transp. frame
      else if ( firstTop < lastTop & trans1Top < trans2MiddleY & trans1MiddleX > firstLeft ) {trans2X = firstLeft + ((trans2Width - firstLeft) / 2);}   //multi-line transp., icon on top and right from beginning of transp. frame
      else if ( firstTop < lastTop & trans1Top > trans2MiddleY & trans1MiddleX > lastRight ) {trans2X = lastRight;}                                     //multi-line transp., icon at bottom and left from beginning of transp. frame
      else if ( firstTop < lastTop & trans1Top > trans2MiddleY & trans1MiddleX < lastRight ) {trans2X = (lastRight / 2) + (pageLeft / 2);}              //multi-line transp., icon at bottom and right from beginning of transp. frame
      else if ( trans1MiddleX > firstLeft & trans1MiddleX < lastRight ) {trans2X = firstLeft + ((lastRight - firstLeft) / 2);}                          //X in middle 
      else if ( trans1Left > trans2MiddleX ) {trans2X = lastRight;}                                                                                     //X on right 
      else if ( trans1Left < trans2MiddleX ) {trans2X = firstLeft;}                                                                                     //X on left 
      
      // *** final position for trans2 (text box): Y ***
      // multi-line trans., icon is on first line of beginning of transposition (left from where the frame starts)
      if (trans1MiddleY > firstTop & trans1MiddleY < trans2MiddleY & firstTop < lastTop) 
        {
          trans2Y = firstTop - yTopCorr;
        } 
      // one line transposition, Y on bottom (if both trans are on one line)
      else if ($(trans2).position().top / scaleX < parseFloat(trans1MiddleY - 8) & ($(trans2).position().top / scaleX) + trans2Height > parseFloat(trans1MiddleY + 8)) 
        { 
          trans2Y = ($( trans2 ).position().top / scaleX) + trans2Height;
        }    
      // Y on top
      else if (trans1MiddleY < firstTop) 
        {
          trans2Y = firstTop - yTopCorr;
        }
      // Y on bottom
      else if (trans1MiddleY > ($( trans2 ).position().top / scaleX) + trans2Height) 
        {
          trans2Y = lastTop + 26;
        }       

      //final position for trans1 (icon): Y
      if ( trans1MiddleY > firstTop & trans1MiddleY < trans2MiddleY & firstTop < lastTop ) {trans1Y = trans1Top;}                                       //multi-line trans., icon is on first line of beginning of transposition (left from where the frame starts)
      else if ( trans1MiddleY > firstTop & trans1MiddleY < parseFloat(firstTop + trans2Height) ) {trans1Y = (trans1Top) + trans1Height;}                //Y bottom (if icon and transposition are on one line)
      else if ( trans2MiddleY > trans1Top & trans2MiddleY < (trans1Top) + trans1Height ) {trans1Y = trans1MiddleY;}                                     //Y in middle
      else if ( trans2MiddleY < trans1Top ) {trans1Y = trans1Top}                                                                                       //Y on top
      else if ( trans2MiddleY > (trans1Top) + trans1Height ) {trans1Y = (trans1Top) + trans1Height;}                                                    //Y on bottom  
      //draw line using SVG
      var strokeColor;
      var pathSVG;
      if ( $(currentElem).parents(".atNoteIn").length ) {
          pathSVG = $(currentElem).closest(".atNoteIn");
      } else if ( $(currentElem).parents(".mouseover_glue").length ) {
          pathSVG = $(currentElem).closest(".mouseover_glue > span");
      } else {
          pathSVG = $(currentMasterPage);
      }
      if ( $(currentElem)[0].classList.contains("delTrans") ) {
          strokeColor = "rgba(128, 128, 128, 0.8)";
      } else {
          strokeColor = "rgba(55, 55, 255,0.8)"
      }

      $(pathSVG).prepend('<svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" id="transConnect'+ElemID2+'" class="transpositionLine"><line x1="'+ trans1X +'" y1="'+ trans1Y +'" x2="'+ trans2X +'" y2="'+ trans2Y +'" stroke-dasharray="4,4" style="stroke:' + strokeColor + ';stroke-width:2" /></svg>');
}   
 

//after mouse leaves transposition remove line
function _transDisConnect(currentElem) {
    let ElemID2;
    if ( currentElem.classList.contains("modBackground")) {                       //Transpositions in a <mod> element have their mouse events redirected.
      ElemID2 = currentElem.querySelectorAll(".transpositionOuter")[0].id;
    } else {ElemID2 = currentElem.id;}
    if ($( "#transConnect"+ElemID2 ).length) {
        $( "#transConnect"+ElemID2 ).fadeOut(1500, function() {
            $( "#transConnect"+ElemID2 ).remove();
        });
      }
  }
  

//toggle visibility of overwritten/ -typed characters in <mod> elements
function _showOrig(currentInstance){
    let modVisOn, modVisOff
    if ( currentInstance.querySelectorAll(".floatadd").length ) {                   //if <mod> includes a "float" CSS "fadeIn" cannot be used
        modVisOn = "modVisOn2";
        modVisOff = "modVisOff2";
    } else {
        modVisOn = "modVisOn";
        modVisOff = "modVisOff";
    }
    currentInstance.children[0].classList.add(modVisOff);
    currentInstance.children[0].classList.remove(modVisOn);
    currentInstance.children[1].classList.add(modVisOn);
    currentInstance.children[1].classList.remove(modVisOff);
    if (currentInstance.querySelectorAll(".floatInsertionMark").length) {           //if there is a "float" in the <mod> trigger it here and add a mouseleave event as well, since mouseover is taken already by the <mod>
        _floatAddOn(currentInstance.querySelectorAll(".floatInsertionMark"));
        currentInstance.addEventListener("mouseout", (evt) => _floatAddOff(currentInstance.querySelectorAll(".floatInsertionMark")));
    }
    currentInstance.addEventListener("mouseleave", function() {
        setTimeout(function(){
            currentInstance.children[0].classList.add(modVisOn);
            currentInstance.children[0].classList.remove(modVisOff);
            currentInstance.children[1].classList.add(modVisOff);
            currentInstance.children[1].classList.remove(modVisOn);
        }, 2000);
    });
}


//highlight border of additions defined as float, and connect insertionmark and float container with curved line
function _floatAddOn(currentElem){
    $(currentElem).parents(".floatadd").removeClass("interactive_floatAdd");                  //also remove mouseover from parent floats if they exist (will be re-added on mouseout)
    if ($(currentElem).closest(".modBackground").length === 0) {$(currentElem).toggleClass('floatAddFlash');}     //toggle floatAdd background only when not in <mod>
    $(currentElem).find(".floatadd:not(.wInsMark, .atNoteIn > span > .floatadd)").toggleClass('floatAddFlash');
    if ($(currentElem).hasClass('floatInsertionMark')) {                                      //select the correct container, either the insertionmark or the float box
        $(currentElem).next().toggleClass('floatAddFlash');                                   //depending from where the mouseover was triggered
        $(currentElem).next().find(".floatadd:not(.wInsMark, .atNoteIn > span > .floatadd)").toggleClass('floatAddFlash');
        $(currentElem).next().find(".inFloatAdd").addClass('inFloatAdd2');                    //any additional containers added to the float
    } else {
        $(currentElem).prev().toggleClass('floatAddFlash');
        
    }
    //Connecting lines between arrow and text:
    var arrowObj, floatObj, svgWidth, svgHeight, svgTop, svgLeft ,curveConX,
        curveConY, lineStartX, lineStartY, lineEndX, lineEndY, contextCont;
    if ( $(currentElem).hasClass("floatInsertionMark") ) {                                    //get the insertionmark and the float box
        if ( $(currentElem).parents(".modBackground").length ) {
          arrowObj = $(currentElem).parents(".modBackground");
        } else {arrowObj = $(currentElem);}                                                   
          floatObj = $(currentElem).next();
    } else {
        arrowObj = $(currentElem).prev();
        floatObj = $(currentElem);
    }
    if ( $(currentElem).parents(".attachedNote").length ) {                                   //select context container for calculating and placing the line
        contextCont = $(currentElem).closest(".attachedNote > span");                         //".atNoteIn" = attached notes
    } else if ( $(currentElem).parents(".mouseover_glue").length ) {
        contextCont = $(currentElem).closest(".mouseover_glue > span");
    } else {
        contextCont = $(currentElem).parents("#masterPage");
    } 
      var scaleX = ($( currentElem ).parents(".pages")[0].getBoundingClientRect().width / $( currentElem ).parents(".pages")[0].offsetWidth);
      var arrowWidth = $(arrowObj)[0].getBoundingClientRect().width / scaleX;
      var floatWidth = $(floatObj)[0].getBoundingClientRect().width / scaleX;
      var arrowHeight = $(arrowObj)[0].getBoundingClientRect().height / scaleX;
      var floatHeight = $(floatObj)[0].getBoundingClientRect().height / scaleX;
      var marginTopFloat = parseInt(window.getComputedStyle($(floatObj)[0]).marginTop);       //top position of float box relative to page container
      var pageTop = $(contextCont).offset().top / scaleX;              
      var pageLeft = $(currentElem).parents("#masterPage").offset().left / scaleX;
      var arrowPosTop = $(arrowObj).offset().top / scaleX - pageTop;
      var floatPosTop = arrowPosTop + marginTopFloat;
      var arrowPosLeft = $(arrowObj).offset().left / scaleX - pageLeft;
      var floatPosLeft = $(floatObj).offset().left / scaleX - pageLeft;
      //float box is right from insertionmark ...
      if ( (floatPosLeft + floatWidth / 2) > (arrowPosLeft + arrowWidth /2) ) {               //define a box between the center insertionmark and the center of the float box
          svgWidth = (floatPosLeft + floatWidth / 2) - (arrowPosLeft + arrowWidth /2);
          svgLeft = arrowPosLeft + arrowWidth /2;
      } else {//... or left from insertionmark
          svgWidth = (arrowPosLeft + arrowWidth /2) - (floatPosLeft + floatWidth / 2);
          svgLeft = floatPosLeft + floatWidth / 2;
      }
      //box is beneath insertionmark
      if ( (arrowPosTop + arrowHeight / 2) < (floatPosTop + floatHeight / 2) ) {         
          svgHeight = (floatPosTop + floatHeight / 2) - (arrowPosTop + arrowHeight / 2);
          svgTop = arrowPosTop + arrowHeight / 2;
      } else {//box is above insertionmark
          svgHeight = (arrowPosTop + arrowHeight / 2) - (floatPosTop + floatHeight / 2);
          svgTop = floatPosTop + floatHeight / 2;
      }
      //connecting line
      //box is right from insertionmark
      if ( svgLeft === arrowPosLeft + arrowWidth /2 ) {                                       //define start and end points of a line bewteen the corners
          lineStartX = 0;                                                                     //of the box defined earlier
          lineEndX = svgWidth;                                                                //also figure out a control point in between for curvature
          curveConX = (svgWidth / 2) + ((svgWidth / 2) / 2); 
      } else {//box is left from insertionmark
          lineStartX = svgWidth;
          lineEndX = 0;
          curveConX = (svgWidth / 2) - ((svgWidth / 2) / 2); 
      }
      //box is beneath insertionmark
      if ( svgTop === arrowPosTop + arrowHeight / 2 ) {
          lineStartY = 0;
          lineEndY = svgHeight;
          curveConY = (svgHeight / 2) - ((svgHeight / 2) / 2); 
      } else {//box is above insertionmark
          lineStartY = svgHeight;
          lineEndY = 0;
          curveConY = (svgHeight / 2) + ((svgHeight / 2) / 2);
      } 
      if ( svgHeight < 1 ) {svgHeight = 10;}  //At this point height of line container must be higher than 1, otherwise line is not displayed. 
      $(contextCont).append($('<span id="lineCont"/>'));
      $("#lineCont")[0].innerHTML = '<svg class="floatConnect"><path d="M'+lineStartX+','+lineStartY+' Q'+curveConX+','+curveConY+' '+lineEndX+','+lineEndY+'" style="stroke: rgba(0, 139, 210,.5); stroke-width: 2px; fill: none;" stroke-linejoin="round"/></svg>';
      $(".floatConnect").attr('width', svgWidth);
      $(".floatConnect").attr('height', svgHeight);
      $(".floatConnect").css("top", svgTop);
      $(".floatConnect").css("left", svgLeft);
      $(currentElem).find(".inFloatAdd").addClass('inFloatAdd2');                                      //any additional containers added to the float
}


function _floatAddOff(currentElem){  
  $(currentElem).parents(".floatadd.wInsMark").addClass("interactive_floatAdd");                //re-add mouseover to any parent float containers if they exist (since they have been removed)
  $(currentElem).removeClass('floatAddFlash');
  $(currentElem).find(".floatadd").removeClass('floatAddFlash');
  if ($( currentElem ).hasClass('floatInsertionMark')) {
      $(currentElem).next().removeClass('floatAddFlash');
      $(currentElem).next().find(".floatadd").removeClass('floatAddFlash');
      $(currentElem).next().find(".inFloatAdd").removeClass('inFloatAdd2');
  } else {
      $(currentElem).prev().removeClass('floatAddFlash');
  }
  $(currentElem).find(".wInsMark").removeClass('floatAddFlash');
  $("#lineCont").remove();    //remove connecting line
  $(currentElem).find(".inFloatAdd").removeClass('inFloatAdd2');
}

function _floatAddOnMirrored(currentElem) {
  $(currentElem).addClass("mirrorThis");
  
}

function _floatAddOffMirrored(currentElem) {
  setInterval(function() {
      if ( $(currentElem).is(':hover') ) {}
      else {
          $(currentElem).removeClass("mirrorThis");
          clearInterval();
      }
    }, 3000);
}