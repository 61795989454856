import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeIndexListingItem / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper')
    }
  },

  html(p) {

    console.log("(DEMO) AeIndexListingItem / Template.html() ...");

    return `
      <template data-layout="${p.layout}">
        <div class="wrapper">
          <span class="ae-index-listing-item__label"><a href="${window.location.origin + window.location.pathname}/person?id=${p.idref}&lang=${_lang}">${p.label}</a></span>
          <span class="ae-index-listing-item__predicates">${_prepPredicates(p.predicates)}</span>
          <span class="ae-index-listing-item__ext">${_prepExtLinks(p.gnd)}</span>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  } 
}


function _prepPredicates(p) {

  let predicates = [];

  // get type
  if (p.indexOf("type") > -1) {
    let type = p.substring(p.indexOf("type"));
    type = type.substring(type.indexOf("=") + 1, type.indexOf(";"));
    
    if (!(type === "person")) {
      switch (type) {
        case 'personGrp':
          predicates.push(aeI18n.translate("ae.term.entity.persongrp", _lang));
          break;
        case 'org':
          predicates.push(aeI18n.translate("ae.term.entity.org", _lang));
          break;
        default:
          predicates.push("Error: Type not recognized. Please check index entry.");
          break;
      }
    };
  }

  // get role
  if (p.indexOf("role") > -1) {
    let role = p.substring(p.indexOf("role"));
    role = role.substring(role.indexOf("=") + 1, role.indexOf(";"));

    if (!(role === "default")) {
      switch (role) {
        case 'dynasty':
          predicates.push(aeI18n.translate("ae.term.entity.persongrp-dynasty", _lang));
          break;
        case 'family':
          predicates.push(aeI18n.translate("ae.term.entity.persongrp-family", _lang));
          break;
        case 'siblings':
          predicates.push(aeI18n.translate("ae.term.entity.persongrp-siblings", _lang));
          break;
        case 'spouses':
          predicates.push(aeI18n.translate("ae.term.entity.persongrp-spouses", _lang));
          break;
        case 'fictitious':
          // FE, 2023-09-30, NOTE: Rendering of person 'roles' is currently under review. 
          // predicates.push(aeI18n.translate("ae.term.entity.person-fictitious", _lang));
          break;
        case 'mythological':
          // FE, 2023-09-30, NOTE: Rendering of person 'roles' is currently under review.
          // predicates.push(aeI18n.translate("ae.term.entity.person-mythological", _lang));
          break;
        case 'biblical':
          // FE, 2023-09-30, NOTE: Rendering of person 'roles' is currently under review.
          // predicates.push(aeI18n.translate("ae.term.entity.person-biblical", _lang));
          break;
        case 'deity':
          // FE, 2023-09-30, NOTE: Rendering of person 'roles' is currently under review.
          // predicates.push(aeI18n.translate("ae.term.entity.person-deity", _lang));
          break;
        case 'publisher':
          predicates.push(aeI18n.translate("ae.term.entity.org-publisher", _lang));
          break;
        default:
          predicates.push("Error: Role not recognized. Please check index entry.");
          break;
      }
    };
  }

  // return some string?
  return (predicates.length) ? "(" + predicates.join(', ') + ")" : "";
}


function _prepExtLinks(p) {

  console.log(p);

  let result = ``;

  if (!(p === "null")) {
    result = `<a href="${p}" target="_blank"><span class="ae-index-listing-item__ext__icon"></span><span class="ae-index-listing-item__ext__label">GND</span></a>`
  }

  return result;
}