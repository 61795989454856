/* 
 * (derived from: robinwieruch.de/web-components-tutorial )
 */
const template = document.createElement('template');

template.innerHTML = `
  <style>
    .container {
      padding: 8px;
     }
 
    button {
      display: block;
      overflow: hidden;
      position: relative;
      padding: 0 16px;
      font-size: 16px;
      font-weight: bold;
      text-overflow: ellipsis;
      whitespace: nowrap;
      cursor: pointer;
      outline: none;
  
      width: 100%;
      height: 40px;
  
      box-sizing: border-box;
      border: 1px solid #a1a1a1;
      background: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(161, 161, 161, 0.4);
      color: #363636;
    }
  </style>
  
  <div class="container">
    <button>Label</button>
  </div>
`;
  
class DemoButton extends HTMLElement {
    
  constructor() {
    super();

    this._shadowRoot = this.attachShadow({mode: 'open'});
    this._shadowRoot.appendChild(template.content.cloneNode(true));

    this.$container = this._shadowRoot.querySelector('.container');
    this.$button = this._shadowRoot.querySelector('button');
  
    // this exposes the custom event 'on-click' as API to the outside
    // to add event, use:
    //  document.querySelector('demo-button').addEventListener('on-click', (evt) => console.log("Clicked! Event detail: " + evt.detail))
    this.$button.addEventListener("click", () => {
      this.dispatchEvent(
        new CustomEvent('on-click', {
          detail: 'Hello from within demo-button!',
        })
      );
    });
  }
  
  connectedCallback() {
    if (this.hasAttribute('as-atom')) {
      this.$container.style.padding = '0px';
    }
  }
  
  // getters/setters for reflecting properties to custom element attributes
  get label() {
    return this.getAttribute('label');
  }
  
  set label(value) {
    this.setAttribute('label', value);
  }
  
  get test() {
    return this.getAttribute('test');
  }
  
  set test(value) {
    this.setAttribute('test', value);
  }
  
  static get observedAttributes() {
    return ['label', 'test'];
  }
  
  attributeChangedCallback(name, oldValue, newValue) {
    this.render();
  }
  
  render() {
    this.$button.innerHTML = this.label;
  }
}


customElements.define('demo-button', DemoButton);