import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeToolItemToggle extends HTMLElement {

  constructor() {
    super();

    console.log("(DEMO) AeToolItemToggle.constructor() ...");

    this._sR = this.attachShadow({mode: 'open'});

    // Properties
    const props = {
      layout: this.layout,
      active: this.active,
      checked: this.checked,
      evtType: this.evtType,
      label: this.label,
    };

    // Load and append style and templates
    const style = document.createElement('style');
    style.innerHTML = Template.css(props);
    this._sR.appendChild(style);
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component depending on @layout
    this._content = this._render(this.layout);

    // Add event listeners
    // PLEASE NOTE:
    // This one is a temporary solution, re-initialization of 
    // tools should be managed by a wrapper component in the future
    eventBusStd.register('textviewer.view.change', (evt) => this._onTextViewChange(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout', 'checked'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeToolItemToggle.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        this._content = this._render(newVal);
        break;
      case 'checked':
        // this._toggle(newVal);
        break;
    }
  }

  
  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set active(val) {
    this.setAttribute("active", val);
  }

  get active() {
    return this.getAttribute("active");
  }

  set checked(val) {
    this.setAttribute("checked", val);
  }

  get checked() {
    return this.getAttribute("checked");
  }

  set evtType(val) {
    this.setAttribute("evt-type", val);
  }

  get evtType() {
    return this.getAttribute("evt-type");
  }

  set label(val) {
    this.setAttribute("label", val);
  }

  get label() {
    return this.getAttribute("label");
  }

  set payload(val) {
    this.setAttribute("payload", val);
  }

  get payload() {
    return this.getAttribute("payload");
  }


  
  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeToolItemToggle._render() ...");

    // Default template (@data-layout)
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this._sR.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this._sR);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeToolItemToggle._initializeView() ...");

    // Init event listener vor checkbox
    this._dom.controls.addEventListener('click', (evt) => {
      this._toggle(evt);
    })

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _toggle(evt) {

    if (this.active === "true") {

      let state = "off";
      let payload = this.payload ? this.payload : ""; 
      
      if (this.checked === "true") {
        this.checked = "false";
        this._dom.toggleBtn.classList.toggle('on');
        state = "off";
      } else {
        this.checked = "true";
        this._dom.toggleBtn.classList.toggle('on');
        state = "on";
      }
  
      let ce = new CustomEvent(this.evtType, {
        detail: {
          state: state,
          payload: payload,
        }
      });
      eventBusStd.fire(ce);
    }
  }


  _onTextViewChange(evt) {

    // Check if we are in charge for the textviewer that emitted the event
    // If true, reset without sending event
    if (this.closest(".ae-textviewer-tools--standoff")) {
      // There are "stand-off tools", so we are on single text view
      this.checked = "false";
      this._dom.toggleBtn.classList.remove('on');
    } else {
      // Check, if tools are within emitting textviewer
      if (this.closest("ae-textviewer") === evt.detail.viewer) {
        this.checked = "false";
        this._dom.toggleBtn.classList.remove('on');
      }
    }
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-tool-item-toggle")) {
  customElements.define("ae-tool-item-toggle", AeToolItemToggle);
}