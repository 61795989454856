import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeIndexListingHeader extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeIndexListingHeader.connectedCallback() ...");

    // Initial filter settings (check URL search params)
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this._filter = {
      "page": params.page ? params.page : "A",
    };

    // Prepare properties object
    const props = {
      layout: this.getAttribute('layout'),
      page: this._filter.page,
    };

    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', Template.html(props));
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    // ... none so far ...
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeIndexListingHeader.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeIndexListingHeader._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      if (this.querySelector('style')) {
        this.querySelector('style').remove();
      }
      
      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
      this.insertAdjacentHTML('afterbegin', '<style>' + Template.css({layout: layout}) + '</style>');
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeIndexListingHeader._initializeView() ...");

    // ...

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }

}


// Define custom element (avoid re-initialization)
if (!customElements.get("ae-index-listing-header")) {
  customElements.define("ae-index-listing-header", AeIndexListingHeader);
}