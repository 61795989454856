import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeIndexListingContent extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeIndexListingContent.connectedCallback() ...");

    // Prepare properties object
    const props = {
      layout: this.getAttribute('layout'),
      dataSource: this.getAttribute('data-source'),
    };

    // Initial filter settings (check URL search params)
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    this._filter = {
      "page": params.page ? params.page : "A",
    };
    
    // Load and append style
    this.insertAdjacentHTML('afterbegin', Template.html(props));
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    eventBusStd.register('button-tab_select_event', (evt) => this._onSelectionChange(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout', 'data-source'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeIndexListingContent.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
      case 'data-source':
        if (this.dataSource && (this.dataSource != this._loadedDataSource)) {
          this._loadData(newVal);
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set dataSource(val) {
    this.setAttribute("data-source", val);
  }

  get dataSource() {
    return this.getAttribute("data-source");
  }


  // *** Methods ***

  _loadData(dataSource) {

    console.log("(DEMO) AeIndexListingContent._loadData() ...");


    // --- Load content ---

    if (dataSource) {

      let requestURL = new URL(window.location.origin + window.location.pathname + "/~api/index/persons/");
      requestURL.searchParams.set('ae-id', dataSource);

      let xhr = new XMLHttpRequest();
      xhr.open("GET", requestURL, true);
      xhr.send();

      xhr.onload = () => {
        if (xhr.status != 200) { 
          // Analyze HTTP status of the response
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { 
          // Show the result
          console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
          
          // Parse XQuery result as JSON
          this._index = JSON.parse(xhr.response);

          // DEBUG
          console.log(this._index);
          console.log(this._index.persons.person.length);
          console.log(this._index.persons.person[0]['id']);

          // Render entry items
          Template.renderIndexEntries(this._dom.indexList, this._index, this._filter);
        }
      };

      xhr.onprogress = function(event) {
        if (event.lengthComputable) {
          // console.log(`Received ${event.loaded} of ${event.total} bytes`);
        } else {
          // console.log(`Received ${event.loaded} bytes`); // No content length
        }
      };

      xhr.onerror = function() {
        console.log("Request failed");
      };
    }

    // Save name of resource currently loaded to variable. This variable can be used to prevent double loading.
    this._loadedDataSource = dataSource;
  };


  _render(layout) {

    console.log("(DEMO) AeIndexListingContent._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      if (this.querySelector('style')) {
        this.querySelector('style').remove();
      }

      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
      this.insertAdjacentHTML('afterbegin', '<style>' + Template.css({layout: layout}) + '</style>');
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize component size and view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeIndexListingContent._initializeView() ...");

    // Load data, if a text resource is specified and has not been loaded yet
    if (this.dataSource && (this.dataSource != this._loadedDataSource)) {
      this._loadData(this.dataSource);
    }

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }

  _onSelectionChange(evt) {
    // Update filter
    this._filter = {
      "page": evt.detail.key,
    };
    // Render entry items
    Template.renderIndexEntries(this._dom.indexList, this._index, this._filter);
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-index-listing-content")) {
  customElements.define("ae-index-listing-content", AeIndexListingContent);
}