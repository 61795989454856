import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeBookmarkManager {
 
  constructor() {
    this._bookmarks = this._readBookmarksFromLocalStorage();

    // Add event listener
    eventBusStd.register('textviewer.bookmark.change', (evt) => this._onToggleBookmark(evt));
    eventBusStd.register('library.bookmark.change', (evt) => this._onToggleBookmark(evt));
  }


  _onToggleBookmark(evt) {
    
    let bookmarkObj = {
      textResource: evt.detail.textResource,
      textVersion: evt.detail.textVersion,
      timestamp: evt.detail.timestamp ? evt.detail.timestamp : ""
    }

    if (evt.detail.action === "add") {
      this._addBookmark(bookmarkObj);
    } else {
      this._removeBookmark(bookmarkObj);
    }
    
  }


  _readBookmarksFromLocalStorage() {

    let bmArr = [];

    if (localStorage.getItem("user")) {

      const lsUserObj = JSON.parse(localStorage.getItem("user"));

      if (lsUserObj["config"]["bookmarks"]) {
        bmArr = lsUserObj["config"]["bookmarks"];
      } else {
        bmArr = [];
      }

    } else {
      bmArr = [];
    }

    return bmArr;

  }


  _writeBookmarksToLocalStorage() {

    // *** Check, if user.config.bookmarks exists ***

    // Get user object
    let lsUserObj = JSON.parse(localStorage.getItem("user"));

    // Does "user" object exist?
    if (lsUserObj) {
      // Does "config" object exist?
      if (lsUserObj["config"]) {
        // Does "bookmarks" property exist?
        if (lsUserObj["config"]["bookmarks"]) {
          // All fine!
        } else {
          lsUserObj.config.bookmarks = []
        }
      } else {
        lsUserObj.config = {
          bookmarks: []
        }
      }
    } else {
      lsUserObj = {
        config: {
          bookmarks: []
        }
      }
    }


    // *** Update ***

    // Update bookmarks
    lsUserObj["config"]["bookmarks"] = this._bookmarks;

    // Write to localStorage
    localStorage.setItem("user", JSON.stringify(lsUserObj));
    
  }
  

  _addBookmark(bmObj) {
    // Check, if text resource version is not bookmarked already 
    if (!this._bookmarks.some(e => (e.textResource === bmObj.textResource && e.textVersion === bmObj.textVersion))) {
      this._bookmarks.push(bmObj);
    }

    // Update localStorage
    this._writeBookmarksToLocalStorage()
  }


  _removeBookmark(bmObj) {
    // Remove all matching bookmarks (just in case - should always be only 1)
    this._bookmarks = this._bookmarks.filter(e => !(e.textResource === bmObj.textResource && e.textVersion === bmObj.textVersion));
  
    // Update localStorage
    this._writeBookmarksToLocalStorage()
  }


  getBookmarks() {
    return this._readBookmarksFromLocalStorage();
  }

}