/* 
 * Functions specific to text view "constituted"
 */

export {initConsView};


/* 
 * initConsView()
 *
 * Interface for text view "cons"
 * 
 * @param c    Instance of component "ae-textviewer-content"
 * @param w    Content wrapper
 * @param dom    DOM map
 *
 */
function initConsView(c, w, dom) {
  
  console.log("(DEMO) AeTextviewerContent / initConsView() ...");

  // Toggle text version?
  if (c.textVersion && c.textVersion !== "null") {
    _toggleConsTextVersion(c, w, dom, c.textVersion);
  }

  // Arrange comment icons on margin
  _setCommIcons(w);

  // Add event listeners to comment icons
  let commentIcons = c.querySelectorAll(".displayComments, .displayCommentsFN");
  for (let i = 0; i < commentIcons.length; i++) {
    commentIcons[i].addEventListener("click", (evt) => _openComment(evt, commentIcons[i], w, dom));
    commentIcons[i].addEventListener("mouseover", (evt) => _addLemmaHighlight(evt, commentIcons[i], w));
    commentIcons[i].addEventListener("mouseout", (evt) => _removeLemmaHighlight(evt, commentIcons[i], w));
  }

  // Initialize scaling
  _initScaling(c);

  // If this component resides within BS modal: Initialize IntersectionObserver
  if (c.closest(".tab-pane__div--cons")) {
    c._onDisplay = false;
    _initVisibilityObserver(c);
  }

  // If property 'idref' is set, scroll to id position
  if (c.idref) {
    const elemTarget = c.querySelector('#' + c.idref);
    _scrollToTarget(c, elemTarget);
  }
}


/*
 * Toggle text version
 *
 * @param c    Instance of component "ae-textviewer-content"
 * @param w    Content wrapper
 * @param dom    DOM map
 * @param version    Text version ID suffix
 */
function _toggleConsTextVersion(c, w, dom, version) {

  // Hide all versions
  const versionContainers = w.querySelectorAll(".text-version");
  for (let i = 0; i < versionContainers.length; i++) {
    versionContainers[i].classList.remove("show");
  }

  // Show version by ID
  console.log("#" + c.textResource + "_" + version);
  w.querySelector("#" + c.textResource + "_" + version).classList.add("show");
}


/* _scrollToTarget(e)
 *
 * Bring a given target element nicely into view
 * 
 * @param c    Component instance
 * @param e    The target element
 */
function _scrollToTarget(c, e) {
  // TODO: This component should not need to check what is going on outside!
  const navHeight = document.querySelector('ae-navbar nav') ? document.querySelector('ae-navbar nav').getBoundingClientRect().height : 0;
  const textviewerHeaderHeight = c.previousElementSibling ? c.previousElementSibling.getBoundingClientRect().height : 0;
  const offset = navHeight + textviewerHeaderHeight;

  // What to scroll depends on mode
  switch (c.mode) {
    case "default":
      window.scrollTo(0, e.getBoundingClientRect().top - offset);
    case "synopsis":
      c.scrollTo(0, e.getBoundingClientRect().top - offset);
      break;
    default:
      // do nothing
      break;
  }
  
}


/* _initVisibilityObserver(c)
 *
 * When textviewers are appended to the DOM without being in 
 * within the current viewport, scaling content to component will fail.
 * This function initializes a VisibilityObserver to react on 
 * components coming into view.
 * 
 * TODO: Function is independent from view; move to a generic JS.
 *
 * @param c    Component instance
 */
function _initVisibilityObserver(c) {

  // Disconnect former IntersectionObserver
  if (c._io) {
    c._io.disconnect();
  }

  // IntersectionObserver
  c._io = new IntersectionObserver(entries => {
    for (let entry of entries) {
      if (c._onDisplay) {
        // Component was on display before and is already initialized
        // DEBUG console.log("IntersectionObserver: Component was on display before and is already initialized");
        // DEBUG console.log(entry);
      } else {
        if (entry.isIntersecting) {
          // First appearance, initialize scaling
          // DEBUG console.log("IntersectionObserver: First appearance, initialize scaling");
          // DEBUG console.log(entry);
          c._onDisplay = true;
          _initScaling(c);
        }
      }
    }
  });

  // Observe component container
  c._io.observe(c);
}


/* _initScaling(c)
 *
 * Initialize a ResizeObserver for the component that allows to fit
 * content to component boundaries when component size changes.
 * 
 * TODO: Deal with margins?
 *
 * @param c    Component instance
 */
function _initScaling(c) {

  // Disconnect former ResizeObserver
  if (c._ro) {
    c._ro.disconnect();
  }
  
  // Get initial content width
  // Get target element via entry.target
  // Get dimensions via .width, .height., .top, .left
  const contentWidthInitial = c.querySelector(".constBorder").getBoundingClientRect().width;

  // ResizeObserver
  c._ro = new ResizeObserver(entries => {
    for (let entry of entries) {
      const cr = entry.contentRect;
      _resizeComponentContent(c, cr.width, contentWidthInitial);
    }
  });

  // Observe component container
  c._ro.observe(c);
}


/* 
 * _resizeComponentContent(c, componentWidthCurrent, contentWidthInitial)
 *
 * Resize component content by scaling via CSS:
 * If component width is lower than component content width
 * then scale content down.
 * 
 * @param c    Component instance
 * @param componentWidthCurrent    The current width value
 * @param contentWidthInitial    A reference width value (typically the initial width of the component content)
 */
function _resizeComponentContent(c, componentWidthCurrent, contentWidthInitial) {
  const scaleFactor = ((componentWidthCurrent / contentWidthInitial) > 1) ? 1 : (componentWidthCurrent / contentWidthInitial);
  c.querySelector(".constBorder").style.transform = "scale(" + scaleFactor + ")";
}


/* 
 * _addLemmaHighlight()
 *
 * Add class to highlight lemma via CSS
 * 
 * @param evt    event
 * @param elem   current element
 * @param w      wrapper
 */
function _addLemmaHighlight(evt, elem, w) {

  // Get lemma
  let lemma = elem.closest(".lemmaHighlight");
  
  // Add class
  lemma.classList.add("lemHighNorm");
}


/* 
 * _removeLemmaHighlight()
 *
 * Remove class to highlight lemma via CSS
 * 
 * @param evt    event
 * @param elem   current element
 * @param w      wrapper
 */
function _removeLemmaHighlight(evt, elem, w) {

  // Get lemma
  let lemma = elem.closest(".lemmaHighlight");
  
  // If lemma is not active, remove class
  if (!lemma.classList.contains("active")) {
    lemma.classList.remove("lemHighNorm");
  }
  
}


/* 
 * _openComment()
 *
 * Open comment
 * 
 * @param evt    event
 * @param elem   comment icon element
 * @param c   content container
 * @param dom   DOM map
 *
 */
function _openComment(evt, elem, c, dom) {
  
  // Get annotation and lemma elements

  let annotation = elem.querySelector(".displayComments2");
  let lemma = elem.closest(".lemmaHighlight");
  

  // Add lemma highlight

  lemma.classList.add("lemHighNorm");
  lemma.classList.add("active");


  // Show apparatus

  dom.apparatus.setAttribute("visibility", "visible");


  // --- Add annotation to apparatus ---

  // Clone annotation
  const newAnno = annotation.cloneNode(true);
  const newAnnoId = annotation.getAttribute("data-ae-anno-id");

  // Add to apparatus
  dom.apparatus.addAnnotation(newAnno);

  // Show annotation
  dom.apparatus.showAnnotation(newAnnoId);
}


/* 
 * _closeComment()
 *
 * Close comment
 * 
 * @param evt    event
 * @param elem   annotation
 * @param c   content container
 *
 */
function _closeComment(evt, annotation, c) {

  // Get annotation and lemma elements
  let lemma = c.querySelector("[data-ae-anno-id-ref='#" + annotation.getAttribute("data-ae-anno-id") + "']");

  // Close annotation
  annotation.remove();

  // Remove lemma highlight
  lemma.classList.remove("lemHighNorm");
  lemma.classList.remove("active");
}


/* 
 * _setCommIcons(w) 
 * 
 * Positioning of comment icons
 * 
 * Check whether icons for displaying comments are placed on top of each other (in case there are two or more lemmata on the same line).
 * If so shift the icons to the left or top or bottom. For now works for 4 icons on one line, can be expanded.
 * 
 * @param w    The content wrapper
 */
function _setCommIcons(w) {

  // FE, 2022-03-17, MOD: Get "border"
  let curConstBorder = w.querySelector(".constBorder");
  let uniquePos = [];
  let allCommentIcons = $(curConstBorder).find(".displayComments").get().reverse();
  let allCommentIconsFN = $(curConstBorder).find(".displayCommentsFN").get().reverse();
  
  // should there be footnote icons ...
  // give comment a random unique ID for later use in showComm()
  for (let i = 0; i < allCommentIconsFN.length; i++) {                                                  
    const randomID = Math.floor(Math.random() * 9999999);
    $(allCommentIconsFN[i]).data('commentID', randomID);
    const constComment = $(allCommentIconsFN[i]).children();
    $(constComment).attr("id", randomID);
  }
  
  // iterate through all comment icons
  for (let i = 0; i < allCommentIcons.length; i++) {                                                  
    
    // give comment a random unique ID for later use in showComm()
    const randomID = Math.floor(Math.random() * 9999999);
    $(allCommentIcons[i]).data('commentID', randomID);
    const constComment = $(allCommentIcons[i]).children();
    $(constComment).attr("id", randomID);

    // get their x and y
    let curTop = $(allCommentIcons[i]).offset().top;                                                
    let curLeft = parseInt($(allCommentIcons[i]).offset().left);
    // store it as an object
    let iconCoord = {"x": curLeft, "y": curTop};   
      
    // check whether collection of icon positions has icon | FE: Get icons with same vertical position                                           
    let checkIcon = $.grep(uniquePos, function(obj) {
      // with current top position  
      return obj.y === curTop;                                                                   
    });

    // FE: If vertical position differs, add icon coordinates to array of icon coordinates with unique vertical position
    // FE: else avoid placing icons on top of each other
    if (checkIcon.length === 0) {                                                                 
      uniquePos.push(iconCoord);
    } else {
      // check whether there is already an icon to the left
      let checkLeft = $.grep(uniquePos, function(obj) {                                            
        return obj.y === curTop && obj.x === curLeft - 20; 
      });
      // FE: If not, place icon further left, else avoid placing icons on top of each other
      if (checkLeft.length === 0) {
        $(allCommentIcons[i]).offset({left: curLeft - 20});
        var iconCoordNew = {"x": curLeft - 20, "y": curTop};
        uniquePos.push(iconCoordNew);
      } else {
        // FE: check whether there is already an icon ABOVE on the right side
        var checkBot = $.grep(uniquePos, function(obj) {                                         
          return obj.y === curTop - 20 && obj.x === curLeft; 
        });
        // FE: If not, place icon ABOVE, else avoid placing icons on top of each other
        if (checkBot.length === 0) {
          $(allCommentIcons[i]).offset({top: curTop - 20});
          var iconCoordNew = {"x": curLeft, "y": curTop - 20};
            uniquePos.push(iconCoordNew);
        } else {
          // FE: check whether there is already an icon BELOW on the right side
          var checkTop = $.grep(uniquePos, function(obj) {                                 
            return obj.y === curTop + 20 && obj.x === curLeft; 
          });
          // FE: If not, place icon BELOW - otherwise the icon will not be placed at all
          if (checkTop.length === 0) {
            $(allCommentIcons[i]).offset({top: curTop + 20});
            var iconCoordNew = {"x": curLeft, "y": curTop + 20};
            uniquePos.push(iconCoordNew);
          }      
        }
      } 
    }
          
  } 
  
  
    //should there be footnote icons, adjust their position as well
  if ($(curConstBorder).find(".mouseover_aNotesWrap").length) {
    setTimeout(() => { //IK: Time out work around because asynch. page load
    var scaleX = (curConstBorder.getBoundingClientRect().width / curConstBorder.offsetWidth);     // get overall scaling value from current text container (set in goldenconfig.js)
    var pageLeft = curConstBorder.getBoundingClientRect().left;
    $(curConstBorder).find(".mouseover_aNotesWrap").each(function() {
        var currentIconLeft = $(this)[0].getBoundingClientRect().left;
        var neededShift = (currentIconLeft / scaleX) - (pageLeft / scaleX);
        $(this).find('.mouseover_aNotes2').css('margin-left', - neededShift);
    });
  }, "2500");
  }  
  
}
