import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeTextviewerHeader / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      selectView: scope.querySelector('.ae-textviewer-header__controls-view__view__select'),
      selectTextVersion: scope.querySelector('.ae-textviewer-header__text__select'),
      edremLinks: scope.querySelectorAll('[data-ae-js="add-href-to-edrem"]')
    }
  },

  // Templates
  html(p) {
    return `
    <template data-layout="default">
      <div class="wrapper">
        <div class="ae-textviewer-header__controls-view">
          <div class="ae-textviewer-header__controls-view__meta">
            <span class="tool-icon" data-toggle="modal" data-target="#${p.guidelinesModalId}" title="${aeI18n.translate("component.textviewer-header.tooltip.editorial-guidelines", _lang)}">
              <span class="fa fa-question-circle"></span>
            </span>
            <span class="tool-icon" data-toggle="modal" data-target="#${p.citationModalId}" title="${aeI18n.translate("component.textviewer-header.tooltip.citation-recommendation", _lang)}">
              <span class="fa fa-quote-left"></span>
            </span>
            <a href="#" data-ae-js="add-href-to-edrem" class="tool-icon not-available" title="${aeI18n.translate("component.textviewer-header.tooltip.editorial-commentary", _lang)}">
              <span class="fa fa-comment"></span>
            </a>
          </div>
          <div class="ae-textviewer-header__controls-view__view">
            <div class="ae-textviewer-header__controls-view__view__form">
              <form>
                <select class="ae-textviewer-header__controls-view__view__select">
                  ${_buildOptions(p.views)}
                </select>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template data-layout="standalone">
      <div class="wrapper">

        <div class="ae-textviewer-header__text">
          <form class="ae-textviewer-header__text__header">
            <select class="ae-textviewer-header__text__select">
              <option>Reading text index...</option>
            </select>
          </form> <!--
          <div class="ae-textviewer-header__text__menu">
            <span class="tool-icon disabled">
              <span class="fa fa-database"></span>
            </span>
          </div> -->
        </div>

        <div class="ae-textviewer-header__controls-view">
          <div class="ae-textviewer-header__controls-view__meta">
            <span class="tool-icon" data-toggle="modal" data-target="#${p.guidelinesModalId}" title="${aeI18n.translate("component.textviewer-header.tooltip.editorial-guidelines", _lang)}">
              <span class="fa fa-question-circle"></span>
            </span>
            <span class="tool-icon" data-toggle="modal" data-target="#${p.citationModalId}" title="${aeI18n.translate("component.textviewer-header.tooltip.citation-recommendation", _lang)}">
              <span class="fa fa-quote-left"></span>
            </span>
            <a href="#" data-ae-js="add-href-to-edrem" class="tool-icon not-available" title="${aeI18n.translate("component.textviewer-header.tooltip.editorial-commentary", _lang)}">
              <span class="fa fa-comment"></span>
            </a>
          </div>
          <div class="ae-textviewer-header__controls-view__view">
            <div class="ae-textviewer-header__controls-view__view__form">
              <form>
                <select class="ae-textviewer-header__controls-view__view__select">
                  ${_buildOptions(p.views)}
                </select>
              </form>
            </div>
          </div>
        </div>
        <!--
        <div class="ae-textviewer-header__controls-tools">
          <div class="ae-textviewer-header__controls-tools__close">
            <span class="tool-icon disabled">
              <span class="fa fa-window-close"></span>
            </span>
          </div>
          <div class="ae-textviewer-header__controls-tools__tools">
            <span class="tool-icon disabled">
              <span class="fa fa-tools"></span>
            </span>
          </div>
        </div>
        -->
      </div>
    </template>
    <template data-layout="preview">
      <div class="wrapper">
        <div class="ae-textviewer-header__controls-view">
          <div class="ae-textviewer-header__controls-view__view">
            <div class="ae-textviewer-header__controls-view__view__form">
              <form>
                <select class="ae-textviewer-header__controls-view__view__select">
                  <option value="dipl">${aeI18n.translate("component.textviewer-header.dropdown-view.dipl", _lang)}</option>
                  <option value="cons">${aeI18n.translate("component.textviewer-header.dropdown-view.cons", _lang)}</option>
                  <option value="facs">${aeI18n.translate("component.textviewer-header.dropdown-view.facs", _lang)}</option>
                  <option value="code">${aeI18n.translate("component.textviewer-header.dropdown-view.code", _lang)}</option>
                </select>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
      `;
  },

  renderTextSelectionMenu(scope, data) {

    console.log("(DEMO) AeTextviewerHeader / Template.renderTextSelectionMenu() ...");

    let selectElem = null;

    // Remove existing menu items
    if (scope.querySelector('.ae-textviewer-header__text__select option')) {
      selectElem = scope.querySelector('.ae-textviewer-header__text__select');
      selectElem.textContent = '';
    }

    // Iterate through dataset and append items
    for (let i = 0; i < data.item.length; i++) {
      // Don't render sample items or items of doctype editorial.*
      if (data.item[i]['ae:vol'] !== 'Sample' && data.item[i]['ae:doctype'] !== 'editorial') {
        const title = data.item[i]['title'].find(t => t['xml:lang'] === _lang);
        const label = `${aeI18n.translate("ae.term.edition.volume--short", _lang)} ${data.item[i]['ae:vol']} | ${aeI18n.translate(`ae.volume.${data.item[i]['ae:vol']}.title--short`, _lang)} | ${title['#text']}` ;
        let option = document.createElement('option');
        option.setAttribute("value", data.item[i]['ae:id']);
        option.innerHTML = label;
        selectElem.append(option);
      }
    };
  },

  renderCitationRecommendation(scope, p, modalId) {

    console.log("(DEMO) AeTextviewerHeader / Template.renderCitationRecommendation() ...");
    
    // Remove existing modal
    if (scope.querySelector('#' + modalId)) {
      scope.querySelector('#' + modalId).remove();
    }

    // There might be multiple authors and/or editors
    let authors = [];
    let editors = [];
  
    // Start iterating at position 1 for first entry in array is always a dummy entry to assure array serialization
    for (let i = 1; i < p.authors.length; i++) {
      authors.push(' <span class="author">' + p.authors[i] + '</span>');
    };
    for (let i = 1; i < p.editors.length; i++) {
      editors.push(' <span class="editor">' + p.editors[i] + '</span>');
    };

    // Return a Bootstrap 3 Modal
    return `
      <div class="modal" id="${modalId}" role="dialog">
        <!-- Bootstrap 3 Modal: Citation Recommendation -->
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">x</button>
              <h4 class="modal-title">${aeI18n.translate("component.textviewer-header.tooltip.citation-recommendation", _lang)}</h4>
            </div>
            <div class="modal-body">
              <p>
                <span class="citation">
                  ${authors}:
                  <span class="title">${p.title}</span>${/\?$/.test(p.title) ? '' : '.'}
                  <span class="titleEd">${p.titleEd ? p.titleEd : ''}</span>${p.titleEd ? '.' : ''}
                  In: <span class="edition">Hannah Arendt. ${aeI18n.translate("ae.title.edition-part", _lang)}.
                  ${p.volumeTitle} (${aeI18n.translate("ae.term.edition.volume--short", _lang)} <span class="vol">${p.volumeNumber}</span></span>).
                  ${aeI18n.translate("ae.term.edition.citation.ed--short", _lang)} ${editors}.
                  ${aeI18n.translate("ae.term.edition.citation.webportal-version", _lang)} ${p.editionVersion} ${aeI18n.translate("ae.term.edition.citation.of-date", _lang)} ${p.editionPubDate}.
                  URL: <span class="url">${p.url}</span>.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    `;
  },

  renderGuidelines(dataDipl, dataCons, view, modalId) {

    console.log("(DEMO) AeTextviewerHeader / Template.renderGuidelines() ...");

    // Prepare class values for modal
    const showContentDipl = (view === "dipl") ? "active" : "";
    const showContentCons = (view === "cons") ? "active" : "";

    // Return a Bootstrap 3 Modal // ${data}
    return `
      <div class="modal" id="${modalId}" role="dialog" data-view="${view}">
        <!-- Bootstrap 3 Modal: Guidelines for diplomatic and constituted text representation -->
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">x</button>
              <h4 class="modal-title">${aeI18n.translate("component.textviewer-header.tooltip.editorial-guidelines", _lang)}</h4>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs">
                <li class="nav-tab__item--dipl ${showContentDipl}"><a data-toggle="tab" href="#${modalId}--dipl">${aeI18n.translate("component.textviewer-header.dropdown-view.dipl", _lang)}</a></li>
                <li class="nav-tab__item--cons ${showContentCons}"><a data-toggle="tab" href="#${modalId}--cons">${aeI18n.translate("component.textviewer-header.dropdown-view.cons", _lang)}</a></li>
              </ul>
              <div class="tab-content">
                <div id="${modalId}--dipl" class="tab-pane__div--dipl tab-pane fade in ${showContentDipl}">
                  ${dataDipl}
                </div>
                <div id="${modalId}--cons" class="tab-pane__div--cons tab-pane fade in ${showContentCons}">
                  ${dataCons}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  }
}


function _buildOptions(views) {

  // HTML snippets
  const diplOption = (views.includes('dipl')) ? `<option value="dipl">${aeI18n.translate("component.textviewer-header.dropdown-view.dipl", _lang)}</option>` : ``;
  const consOption = (views.includes('cons')) ? `<option value="cons">${aeI18n.translate("component.textviewer-header.dropdown-view.cons", _lang)}</option>` : ``;
  const facsOption = (views.includes('facs')) ? `<option value="facs">${aeI18n.translate("component.textviewer-header.dropdown-view.facs", _lang)}</option>` : ``;
  const codeOption = (views.includes('code')) ? `<option value="code">${aeI18n.translate("component.textviewer-header.dropdown-view.code", _lang)}</option> ` : ``;

  return `
    ${diplOption}
    ${consOption}
    ${facsOption}
    ${codeOption}
  `;
}