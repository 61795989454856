import styleTextCustomElementGeneric from '../../scss/ae-components/ae-custom-element--generic.scss';
const styleCustomElementGeneric = document.createElement('style');
styleCustomElementGeneric.appendChild(document.createTextNode(styleTextCustomElementGeneric));


const templateGreetingSpecific = document.createElement('template');

templateGreetingSpecific.innerHTML = `
  <style>
    .greeting {
      color: blue;
    }
  </style>

  <div>
    <slot class="greeting" name="greeting">Hello</slot>
    <slot name="entity">World</slot>
  </div>
`;


const templateGreetingGeneric = document.createElement('template');

templateGreetingGeneric.innerHTML = 
  styleCustomElementGeneric.outerHTML + `<div>
    <slot class="greeting" name="greeting">Hello</slot>
  </div>
`;


class AeCustomElement extends HTMLElement {

  constructor() {
    super();
    this._sR = this.attachShadow({mode: 'open'});

    if (this.getAttribute('specific') === 'true') {
      this._sR.appendChild(templateGreetingSpecific.content.cloneNode(true));
    } else {
      this._sR.appendChild(templateGreetingGeneric.content.cloneNode(true));
    }
    
  }

}


customElements.define('ae-custom-element', AeCustomElement);