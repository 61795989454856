/* 
  AE Footer Class
 */
class AeFooter extends HTMLElement {

  constructor() {
    super();
    // console.log("Hello from AeFooter!");
  }
  
  connectedCallback() {

    // sticky footer
    document.addEventListener("DOMContentLoaded", () => setTimeout(document.getElementsByTagName("ae-footer")[0].stickyFooter(), 200));
    window.addEventListener("resize", () => setTimeout(document.getElementsByTagName("ae-footer")[0].stickyFooter(), 200));

  }

  stickyFooter() {
    let footerHeight = this.offsetHeight;
    document.getElementsByTagName("body")[0].style.marginBottom = footerHeight + "px";
  }

}
  
customElements.define('ae-footer', AeFooter);