import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';
import {auto, createPopper} from '@popperjs/core';


export default class AePopover extends HTMLElement {

  constructor() {
    super();

    /* console.log("(DEMO) AePopover.constructor() ..."); */

    this._sR = this.attachShadow({mode: 'open'});

    // Prepare properties object
    const props = {
      context: this,
      layout: this.layout,
      content: this.content,
      type: this.type,
      popoverId: this.popoverId,
    };

    // Popover Instance (popper)
    let _popoverInstance = null;
    
    // Load and append style and templates
    const style = document.createElement('style');
    style.innerHTML = Template.css(props);
    this._sR.appendChild(style);
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    eventBusStd.register('popover.toggle', (evt) => this._onPopoverToggle(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout', 'show'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AePopover.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
      case 'show':
        if (this._dom && this._popoverInstance) {
          // Only "false" implemented atm
          if (newVal === "false" && !(oldVal === "false")) {
            this._hide();
          }
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set content(val) {
    this.setAttribute("content", val);
  }

  get content() {
    return this.getAttribute("content");
  }

  set popoverId(val) {
    this.setAttribute("popover-id", val);
  }

  get popoverId() {
    return this.getAttribute("popover-id");
  }

  set show(val) {
    this.setAttribute("show", val);
  }

  get show() {
    return this.getAttribute("show");
  }

  set type(val) {
    this.setAttribute("type", val);
  }

  get type() {
    return this.getAttribute("type");
  }


  // *** Methods ***

  _render(layout) {

    /* console.log("(DEMO) AePopover._render() ..."); */

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this._sR.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this._sR);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    /* console.log("(DEMO) AePopover._initializeView() ..."); */

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _onPopoverToggle(evt) {

    /* console.log("(DEMO) AePopover._onPopoverToggle() ..."); */

    // Only proceed, when necessary
    // - type matches
    // - scope is correct
    if (evt.detail.type === this.type && evt.detail.scope.contains(this)) {

      const popover = evt.detail.scope.querySelector('[popover-id="' + evt.detail.item.getAttribute('data-popover-ref') + '"]');

      // If this is the designated tooltip ...
      if (popover === this) {

        // ... create popover instance, if not done yet
        const tooltip = this._dom.tooltip;
        this._popoverInstance = this._popoverInstance ? this._popoverInstance : createPopper(evt.detail.item, tooltip, {
          placement: 'top',
          modifiers: [
            {
              name: 'flip',
              options: {
                boundary: evt.detail.boundary,
                fallbackPlacements: 'auto',
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
          strategy: 'fixed',
        });

        // ... and toggle popover
        this._toggleTooltip(this, evt.detail.item, tooltip, this._popoverInstance);
      }

    }
  }


  _toggleTooltip(c, item, tooltip, popperInstance) {

    switch (tooltip.getAttribute('data-show') === 'data-show') {
      case true:
        this._hideTooltip(c, tooltip, popperInstance);
        break;
      case false:
        // Show only, when item has attribute @data-popover="enabled"
        if (item.getAttribute('data-popover') === 'enabled') {
          this._showTooltip(c, tooltip, popperInstance);
        }
        break;
    } 
  }


  _showTooltip(c, tooltip, popperInstance) {
    this.show = "true";
    tooltip.setAttribute('data-show', 'data-show');

    // Load data (component: set @load="load")
    if (tooltip.getAttribute('data-loaded') !== 'data-loaded') {
      // Set attribute for 'loadable' content
      if (c._dom.wrapper.querySelector('[loaded=""]')) {
        c._dom.wrapper.querySelector('[loaded]').setAttribute('loaded', 'loaded');
      }
    }
    tooltip.setAttribute('data-loaded', 'data-loaded');

    // Enable the event listeners (for performance reasons)
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }));
  
    popperInstance.update();
  }


  _hideTooltip(c, tooltip, popperInstance) {
    this.show = "false";
    tooltip.removeAttribute('data-show');

    // Disable the event listeners (for performance reasons)
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }));
  }

  _hide() {
    this._hideTooltip(this, this._dom.tooltip, this._popoverInstance);
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-popover")) {
  customElements.define("ae-popover", AePopover);
}