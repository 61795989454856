import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


// TODO:
// - REFACTORING: Filter should be managed here!
// - REFACTORING: State changes should be signaled by this parent component 

export default class AeIndexListing extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeIndexListing.connectedCallback() ...");

    // Prepare properties object
    const props = {
      layout: this.layout,
      dataSource: this.dataSource,
    };
    
    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    // ... none so far ...
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout', 'data-source'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeIndexListing.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        this._stateChange(name, newVal);
        break;
      case 'data-source':
        if (this._dom) {
          this._content = this._initializeView();
        }
        this._stateChange("data_source", newVal);
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set dataSource(val) {
    this.setAttribute("data-source", val);
  }

  get dataSource() {
    return this.getAttribute("data-source");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeIndexListing._render() ...");

    // Default template (@data-layout="headless")
    if (!layout) {
      layout = "index-page";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeIndexListing._initializeView() ...");


    // --- Initialize Index Listing and its Subcomponents ---

    // Initialize Subcomponents
    if (this._dom.header) {
      this._initIndexListingHeader();
    }
    this._initIndexListingContent();

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _initIndexListingHeader() {

    console.log("(DEMO) AeIndexListing._initIndexListingHeader() ...");

    // Set properties for ae-index-listing-header
    // ... nothing to set so far ...
  }


  _initIndexListingContent() {

    console.log("(DEMO) AeIndexListing._initIndexListingContent() ...");

    // Set properties for ae-index-listing-content
    // ... nothing to set so far ...
  }


  _stateChange(property, value) {
    
    console.log("(DEMO) AeIndexListing._stateChange(): property = " + property + "; value = " + value);

    // Create custom event
    let ce = new CustomEvent("component_state_change", {
      bubbles: true,
      detail: {
        changedProperty: property,
        newValue: value,
        component: this,
      }
    });

    // Dispatch custom event
    this.dispatchEvent(ce);
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-index-listing")) {
  customElements.define("ae-index-listing", AeIndexListing);
}