import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeTextviewer extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeTextviewer.connectedCallback() ...");

    // Prepare properties object (no properties needed for CSS atm)
    const props = {
    };
    
    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');
    /* this.insertAdjacentHTML('beforeend', Template.html(props)); */

    // Render component
    /* this._content = this._render(this.layout); */

    // Load data, if a text resource is specified and has not been loaded yet
    if (this.textResource && (this.textResource != this._loadedTextResource)) {
      this._loadData(this.textResource);
    }

    // Add event listeners
    eventBusStd.register('textviewer.view.change', (evt) => this._onViewChange(evt));
    eventBusStd.register('textviewer.text-resource.change', (evt) => this._onTextResourceChange(evt));
    eventBusStd.register('textviewer.text-version.change', (evt) => this._onTextVersionChange(evt));
    eventBusStd.register('texttool.toggle.index.person', (evt) => this._onToggleIndexPersons(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['idref', 'layout', 'mode', 'text-resource', 'text-version', 'view'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeTextviewer.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'idref':
        if (this._dom) {
          this._content = this._initializeView();
        }
        this._stateChange(name, newVal);
        break;
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        this._stateChange(name, newVal);
        break;
      case 'mode':
        this._stateChange(name, newVal);
        break;
      case 'text-resource':
        /* if (this._dom) {
          this._content = this._initializeView();
        } */
        if (
            (this.textResource && (this.textResource != this._loadedTextResource)) ||
            ((this.textResource == this._loadedTextResource) && (this.textVersion != this._loadedTextVersion))
          ) {
          this._loadData(newVal);
        }
        this._stateChange("text_resource", newVal);
        break;
      case 'text-version':
          if (this._dom) {
            this._dom.header.setAttribute("text-version", newVal);
            this._dom.content.setAttribute("text-version", newVal);
          }
          this._stateChange("text_version", newVal);
          break;
      case 'view':
        // No initialization, if view is undefined
        if (this._dom && oldVal) {
          this._content = this._initializeView();
        }
        this._stateChange(name, newVal);
        break;
    }
  }


  // *** Getters / Setters ***
  
  set idref(val) {
    this.setAttribute("idref", val);
  }

  get idref() {
    return this.getAttribute("idref");
  }
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set mode(val) {
    this.setAttribute("mode", val);
  }

  get mode() {
    return this.getAttribute("mode");
  }

  set textResource(val) {
    this.setAttribute("text-resource", val);
  }

  get textResource() {
    return this.getAttribute("text-resource");
  }

  set textVersion(val) {
    this.setAttribute("text-version", val);
  }

  get textVersion() {
    return this.getAttribute("text-version");
  }

  set view(val) {
    this.setAttribute("view", val);
  }

  get view() {
    return this.getAttribute("view");
  }

  set views(val) {
    this.setAttribute("views", val);
  }

  get views() {
    return this.getAttribute("views");
  }


  // *** Methods ***

  _loadData(textResource) {

    console.log("(DEMO) AeTextviewer._loadData() ...");


    // Remove templates
    let templatesOld = this.querySelectorAll('template');
    for (let i = 0; i < templatesOld.length; i++) {
      templatesOld[i].remove();
    }
    

    // Load text index
    let requestURL = new URL(window.location.origin + window.location.pathname + "/~api/index/textresources/" + textResource);

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found


        // --- Fallback: Set views = view and render ---

        // Set component property
        this.views = this.view;
        
        // Prepare properties object
        let props = {
          layout: this.layout,
          mode: this.mode,
          textResource: this.textResource,
          textVersion: this.textVersion,
          view: this.view,
          views: this.views,
        }

        // Append templates
        this.insertAdjacentHTML('afterbegin', Template.html(props));

        // Render
        this._content = this._render(this.layout);
      } else { 
        // Show the result
        console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
          
        // Parse XQuery result as JSON
        this._data = JSON.parse(xhr.response);

        // DEBUG
        /* console.log("(DEBUG) ***** LOAD TEXT INDEX DATA *****");
        console.log(requestURL);
        console.log(this._text);
        console.log(this._text.item);
        console.log(this._text.item['ae:views']); */

        // Set component properties
        this.views = this._data.item['ae:views'];
        this.view = this.view ? this.view : this._determineDefaultView(this._data);
        
        // Prepare properties object
        let props = {
          layout: this.layout,
          mode: this.mode,
          textResource: this.textResource,
          textVersion: this.textVersion,
          view: this.view,
          views: this.views,
        }

        // Append templates
        this.insertAdjacentHTML('afterbegin', Template.html(props));

        // Render
        this._content = this._render(this.layout);
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };


    // Save name of resource and version currently loaded to variables. These variables can be used to prevent double loading.
    this._loadedTextResource = textResource;
    this._loadedTextVersion = this.textVersion;
  };


  _render(layout) {

    console.log("(DEMO) AeTextviewer._render() ...");

    // Default template (@data-layout="headless")
    if (!layout) {
      layout = "headless";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeTextviewer._initializeView() ...");


    // --- Initialize Textviewer and its Subcomponents ---

    // Initialize Subcomponents
    if (this._dom.header) {
      this._initTextviewerHeader();
    }
    this._initTextviewerContent();


    // --- Communicate that component is ready ---

    // Create custom event
    let ce = new CustomEvent("component.ready", {
      detail: {
        component: this,
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _initTextviewerHeader() {

    console.log("(DEMO) AeTextviewer._initTextviewerHeader() ...");

    // Set properties for ae-textviewer-header
    if (this.mode != this._dom.header.mode) {
      this._dom.header.setAttribute('mode', this.mode);
    }
    if (this.textResource != this._dom.header.textResource) {
      this._dom.header.setAttribute('text-resource', this.textResource);
    }
    if (this.view != this._dom.header.view) {
      this._dom.header.setAttribute('view', this.view);
    }
    const edremResourceObj = this._data.item['ref'].find(obj => obj.type === 'edrem-uri');
    if (edremResourceObj) {
      this._dom.header.setAttribute('edrem-resource', edremResourceObj['target'].split("/").pop());
    }
  }


  _initTextviewerContent() {

    console.log("(DEMO) AeTextviewer._initTextviewerContent() ...");

    // Set properties for ae-textviewer-content
    if (this.idref != this._dom.content.idref) {
      this._dom.content.setAttribute('idref', this.idref);
    }
    if (this.mode != this._dom.content.mode) {
      this._dom.content.setAttribute('mode', this.mode);
    }
    if (this.textResource != this._dom.content.textResource) {
      this._dom.content.setAttribute('text-resource', this.textResource);
    }
    if (this.view != this._dom.content.view) {
      this._dom.content.setAttribute('view', this.view);
    }
  }


  _onViewChange(evt) {

    console.log("(DEMO) AeTextviewer._onViewChange() ...");

    // Only react, when emitted by own header
    if (evt.detail.viewer === this) {

      // Reflect current view in attribute
      this.view = evt.detail.view;
    }
  }


  _onTextResourceChange(evt) {

    console.log("(DEMO) AeTextviewer._onTextResourceChange() ...");

    // Only react, when emitted by own header
    if (evt.detail.viewer === this) {

      // Reflect current resource in attribute
      this.textResource = evt.detail.textResource;
    }
  }


  _onTextVersionChange(evt) {

    console.log("(DEMO) AeTextviewer._onTextVersionChange() ...");

    // Only react, when emitted by own header
    if (evt.detail.viewer === this) {

      // Reflect current resource and version in attributes
      this.textResource = evt.detail.textResource;
      this.textVersion = evt.detail.textVersion;
    }
  }


  _onToggleIndexPersons(evt) {

    console.log("(DEMO) AeTextviewer._onToggleIndexPersons() ...");

    // TODO: Check if this instance is in scope

    // DEBUG
    console.log("Received toggle event; set highlighting to " + evt.detail.state);
    this._dom.content.toggleIndexHighlighting("person", evt.detail.state);
    this._dom.content.toggleIndexPopovers("person", evt.detail.state);
  }


  _stateChange(property, value) {
    
    console.log("(DEMO) AeTextviewer._stateChange(): property = " + property + "; value = " + value);

    // Create custom event
    let ce = new CustomEvent("component_state_change", {
      bubbles: true,
      detail: {
        changedProperty: property,
        newValue: value,
        component: this,
      }
    });

    // Dispatch custom event
    this.dispatchEvent(ce);
  }


  _determineDefaultView(data) {

    console.log("(DEMO) AeTextviewer._determineDefaultView() ...");

    // Check doctype
    let view = null;
    switch(data.item['ae:doctype']) {
      case 'source.print':
        view = "cons";
        break;
      case 'source.typescript':
        view = "dipl";
        break;
      default:
        view = "cons";
        break;
    }
    
    return view;
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-textviewer")) {
  customElements.define("ae-textviewer", AeTextviewer);
}