import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeTextviewerApparatus extends HTMLElement {

  constructor() {
    super();

    console.log("(DEMO) AeTextviewerApparatus.constructor() ...");

    this._sR = this.attachShadow({mode: 'open'});

    // Internal properties
    this._annotations = [];
    this._current = null;

    // Prepare properties object (no properties needed for CSS atm)
    const props = {
    };

    // Load and append style and templates
    const style = document.createElement('style');
    style.innerHTML = Template.css(props);
    this._sR.appendChild(style);
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    // none so far
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeTextviewerApparatus.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set visibility(val) {
    this.setAttribute("visibility", val);
  }

  get visibility() {
    return this.getAttribute("visibility");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeTextviewerApparatus._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this._sR.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this._sR);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeTextviewerApparatus._initializeView() ...");

    // Initialize buttons
    this._dom.header__btnClose.addEventListener("click", (evt) => this._closeApparatus());
    this._dom.header__btnRemove.addEventListener("click", (evt) => this._removeAnnotation(this._current));
    this._dom.header__btnPrev.addEventListener("click", (evt) => this._prevAnnotation());
    this._dom.header__btnNext.addEventListener("click", (evt) => this._nextAnnotation());
    this._dom.header__btnGoto.addEventListener("click", (evt) => this._gotoLemma(this._current));

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _showApparatus() {
    this.visibility = "visible";
  }


  _hideApparatus() {
    this.visibility = "hidden";
  }


  _closeApparatus() {

    // Create custom event
    let ce = new CustomEvent("textviewer.apparatus.close", {
      detail: {
        viewer: this.closest("ae-textviewer"),
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);

    // Clear and close
    this._clearApparatus();
    this._hideApparatus();

    // Flush annotation array
    this._annotations = [];
    this._current = null;
  }


  _clearApparatus() {

    // Clear UI
    this._dom.header__title.innerHTML = "";
    this._dom.content.innerHTML = "";

    // Update buttons
    this._updateButtons();
  }


  /* 
   * Check by ID, if annotation is in apparatus; return, if true
   */
  _getAnnotation(annoId) {
    return this._annotations.find(item => item.id === annoId);
  }


  _removeAnnotation(annoId) {

    // Create custom event
    let ce = new CustomEvent("textviewer.apparatus.annotation.remove", {
      detail: {
        id: annoId,
        viewer: this.closest("ae-textviewer"),
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);

    // Remove from array
    let annoIndex = this._annotations.findIndex(item => item.id === annoId);
    this._annotations.splice(annoIndex, 1);

    // Update apparatus
    if (this._annotations.length) {
      // Show previous or last remaining annotation
      annoIndex = (annoIndex > 0) ? (annoIndex - 1) : 0;
      this.showAnnotation(this._annotations[annoIndex].id);
    } else {
      // Close apparatus
      this._closeApparatus();
    }

    // Update buttons
    this._updateButtons();
  }


  _prevAnnotation() {

    // Get current index
    let annoIndex = this._annotations.findIndex(item => item.id === this._current);

    // Update apparatus
    if (annoIndex) {
      // Index > 0, go to previous index
      this.showAnnotation(this._annotations[annoIndex - 1].id);
    } else {
      // Index = 0, go to last index
      this.showAnnotation(this._annotations[this._annotations.length - 1].id);
    }
  }


  _nextAnnotation() {

    // Get current index
    let annoIndex = this._annotations.findIndex(item => item.id === this._current);

    // Update apparatus
    if (annoIndex < (this._annotations.length - 1)) {
      // Index < array length, go to next index
      this.showAnnotation(this._annotations[annoIndex + 1].id);
    } else {
      // Index = array length, go to first index
      this.showAnnotation(this._annotations[0].id);
    }
  }

  _gotoLemma(annoId) {
    
    // Create custom event
    let ce = new CustomEvent("textviewer.apparatus.gotoLemma", {
      detail: {
        id: annoId,
        viewer: this.closest("ae-textviewer"),
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);
  }


  /* 
   * Update buttons depending on annotation count
   */
  _updateButtons() {
    if (this._annotations.length <= 1) {
      this._dom.header__btnPrev__icon.classList.add("disabled");
      this._dom.header__btnNext__icon.classList.add("disabled");
    } else {
      this._dom.header__btnPrev__icon.classList.remove("disabled");
      this._dom.header__btnNext__icon.classList.remove("disabled");
    }
  }


  // *** "Public" methods ***

  addAnnotation(annoNode) {

    const annoId = annoNode.getAttribute("data-ae-anno-id");

    // Add annotation, if not in array yet
    if (!(this._getAnnotation(annoId))) {
      const newAnnoObj = {
        id: annoId,
        titleNode: annoNode.querySelector(".ae-anno-note__header__title"),
        contentNode: annoNode.querySelector(".ae-anno-note__content"),
      }
      this._annotations.push(newAnnoObj); 
    }

    // Update buttons
    this._updateButtons();
  }


  showAnnotation(annoId) {

    // Create custom event
    let ce = new CustomEvent("textviewer.apparatus.annotation.show", {
      detail: {
        id: annoId,
        viewer: this.closest("ae-textviewer"),
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);

    // Clear apparatus
    this._clearApparatus();

    // Set current
    this._current = annoId;

    // Get current annotation object
    const currentAnno = this._getAnnotation(annoId);

    // Append to apparatus content
    this._dom.header__title.appendChild(currentAnno.titleNode);
    this._dom.content.appendChild(currentAnno.contentNode);
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-textviewer-apparatus")) {
  customElements.define("ae-textviewer-apparatus", AeTextviewerApparatus);
}