import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';


export default class AeTimelineWrapper extends HTMLElement {

  connectedCallback() {
    
    console.log("(DEMO) AeTimelineWrapper.connectedCallback() ...");

    // Filter object - TODO: Read values from some config item
    this._filterObj = {
      volume: ["02", "03", "06"],
      tag: ["bibl", "publish", "correspondence", "broadcast", "writing", "lecture", "translate", "research", "grant", "travel", "conference", "reading", "other"],
    };

    // Properties
    const props = {
      layout: this.layout,
    };

    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component depending on @layout
    this._content = this._render(this.layout);

    // Add event listeners
    this.addEventListener("ae.timeline.dom-ready", (evt) => Template.loadTimeline("events"));
    eventBusStd.register('index.events.toggle.filter', (evt) => this._onFilterChange(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeTimelineWrapper.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        this._content = this._render(newVal);
        break;
    }
  }

  
  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  
  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeTimelineWrapper._render() ...");

    // Default template (@data-layout)
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeTimelineWrapper._initializeView() ...");

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _onFilterChange(evt) {

    console.log("(DEMO) AeTimelineWrapper._onFilterChange() ...");

    // Get filter parameter from event
    const filter = evt.detail.payload;
    const state = evt.detail.state;

    // Update filter object
    switch(state) {
      case "on":
        this._addFilter(filter);
        break;
      case "off":
        this._removeFilter(filter);
        break;
      default:
        break;
    }


    // Get data and apply filter

    const url = `../docs/volume/data/events_edition_${aeI18n.getLang()}.json`;

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error
            (`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        this._applyFilter(data, this._filterObj);
      })
      .catch((error) => console.error("Unable to fetch data:", error));
  }


  _addFilter(f) {
    // filter strings are of pattern TYPE.VALUE
    const type = f.substring(0, f.indexOf("."));
    this._filterObj[type].push(f.split('.').pop());
  }


  _removeFilter(f) {
    // filter strings are of pattern TYPE.VALUE
    const type = f.substring(0, f.indexOf("."));
    const value = f.split('.').pop();
    const index = this._filterObj[type].indexOf(value);

    this._filterObj[type].splice(index, 1);
  }


  _applyFilter(data, fObj) {

    // Filter data based on filter object

    const volumeFilterSet = new Set(fObj.volume);
    const tagFilterSet = new Set(fObj.tag);

    let eventsFilteredArr = [];
    
    eventsFilteredArr = data.events.filter(function(el) {  
      return el.volume.find(item => volumeFilterSet.has(item));
    });

    eventsFilteredArr = eventsFilteredArr.filter(function(el) {  
      return el.tag.find(item => tagFilterSet.has(item));
    });

    const dataFiltered = {
      events: eventsFilteredArr
    }


    // Reload timeline with filtered dataset
    Template.reloadTimeline(dataFiltered);

  }


  _getData(url) {
    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error
            (`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {return data})
      .catch((error) => console.error("Unable to fetch data:", error));
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-timeline-wrapper")) {
  customElements.define("ae-timeline-wrapper", AeTimelineWrapper);
}