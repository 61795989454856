import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {
    return {
      wrapper: scope.querySelector('.wrapper'),
      controls: scope.querySelector('.ae-tool-item-toggle__controls'),
      toggleBtn: scope.querySelector('.ae-tool-item-toggle__controls .toggle-btn'),
      label: scope.querySelector('.ae-tool-item-toggle__label'),
    }
  },

  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper">
          <div class="ae-tool-item-toggle__controls">
            <span class="toggle-btn" href="#"><i class="far fa-square" title=""></i></span>
          </div>
          <div class="ae-tool-item-toggle__label">
            ${aeI18n.translate(p.label, _lang)}
          </div>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  }
}