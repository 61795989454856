import AeButton from './ae-button.js';

import styleText_AeToolItem from '../../../scss/ae-components/elements/_ae-tool-item.scss';
const styleElement_AeToolItem = document.createElement('style');
styleElement_AeToolItem.appendChild(document.createTextNode(styleText_AeToolItem));


const template_AeToolItem = document.createElement('template');

template_AeToolItem.innerHTML = styleElement_AeToolItem.outerHTML + `
  <div class="ae-tool-item">
    
  </div>
`;
  
class AeToolItem extends AeButton {
    
  constructor() {
    super();

    this._shadowRoot.appendChild(template_AeToolItem.content.cloneNode(true));

    this.$container = this._shadowRoot.querySelector('.ae-tool-item');
  }
 
}

if (!customElements.get('ae-tool-item')) {
  customElements.define('ae-tool-item', AeToolItem);
}