import styleText_AeNavbar from '../../../scss/ae-components/elements/_navbar.scss';
const styleElement_AeNavbar = document.createElement('style');
styleElement_AeNavbar.appendChild(document.createTextNode(styleText_AeNavbar));


/* 
  AE Navigation Bar Class
 */
class AeNavbar extends HTMLElement {

  constructor() {
    super();
    // console.log("Hello from AeNavbar!");
    // this.addEventListener('click', () => console.log("Clicked on 'AeNavbar' component"));
  }

  render() {
  
  }

  connectedCallback() {

    // this.appendChild(styleElement_AeNavbar);

  }
  
}

customElements.define('ae-navbar', AeNavbar);