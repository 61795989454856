import styleText from './_style.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {
    return {
      wrapper: scope.querySelector('.wrapper'),
      slots: scope.querySelectorAll('slot')
    }
  },

  // Templates are not used atm
  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper">
          <span>Slot Content:</span>
          <slot name="field-a">A</slot>
        </div>
      </template>
      <template data-layout="alternative">
        <div class="wrapper">
          <span>Slot Content:</span>
          <slot name="field-a">A</slot>
          <slot name="field-b">B</slot>
          <span>${p.foo}</span>
        </div>
      </template>
      `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  }
}