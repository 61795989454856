import Template from './_template.js';
import {bookmarkLib} from '../ae-bookmark-manager/bookmark-manager-config.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeButtonLibrary extends HTMLElement {

  connectedCallback() {

    const props = {
      property: this.getAttribute('property'),
    };

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize
    this._setNotificationDot();

    // Add event listeners
    eventBusStd.register('library.bookmark.change', (evt) => this._onBookmarkChange(evt));
    eventBusStd.register('textviewer.bookmark.change', (evt) => this._onBookmarkChange(evt));
  }


  // *** Methods ***

  _onBookmarkChange(evt) {
    this._setNotificationDot();
  }


  _setNotificationDot() {

    // If bookmarks > 0, add class
    if (bookmarkLib.getBookmarks().length) {
      this.classList.add("notify");
    } else {
      this.classList.remove("notify");
    }

  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-button-library")) {
  customElements.define("ae-button-library", AeButtonLibrary);
}