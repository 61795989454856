import Template from './_template.js';


export default class AeLogoBox extends HTMLElement {

  constructor() {
    super();
    
    console.log("(DEMO) AeLogoBox.constructor() ...");

    this._sR = this.attachShadow({mode: 'open'});

    // Prepare properties object
    const props = {
      layout: this.layout,
    };

    // Load and append style and templates
    const style = document.createElement('style');
    style.innerHTML = Template.css(props);
    this._sR.appendChild(style);
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component depending on @layout
    this._content = this._render(this.layout);
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeLogoBox.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        this._content = this._render(newVal);
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeLogoBox._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this._sR.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this._sR);

    // Initialize view
    return this._initializeView();
  }
  

  _initializeView() {

    console.log("(DEMO) AeLogoBox._initializeView() ...");

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }
    
}
  
// Define custom element (avoid re-initialization)
if (!customElements.get("ae-logo-box")) {
  customElements.define("ae-logo-box", AeLogoBox);
}