import styleGeneric from './_style-generic.scss';
import styleTextDipl from './_style-dipl.scss';
import styleTextCons from './_style-cons.scss';
import styleTextFacs from './_style-facs.scss';
import styleTextCode from './_style-code.scss';
import styleTify from './_tify.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeTextviewerContent / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      templates: scope.querySelectorAll('template'),
      apparatus: scope.querySelector('ae-textviewer-apparatus'),
      personElements: scope.querySelectorAll('[data-type~="person"]'),
    }
  },

  html(p) {

    console.log("(DEMO) AeTextviewerContent / Template.html() ...");

    return `
      <template data-view="${p.view}">
        <div class="wrapper ae-textviewer-content ae-textviewer-content--${p.view}">
        ${p.content}
        <ae-textviewer-apparatus layout="default" visibility="hidden"> </ae-textviewer-apparatus>
        </div>
      </template>
    `;
  },

  css(p) {
    switch (p.view) {
      case "dipl":
        return `
          ${styleGeneric}
          ${styleTextDipl}
        `;
      case "cons":
        return `
          ${styleGeneric}
          ${styleTextCons}
        `;
      case "facs":
        return `
          ${styleGeneric}
          ${styleTextFacs}
          ${styleTify}
        `;
      case "code":
        return `
          ${styleGeneric}
          ${styleTextCode}
        `;
    }
  }
}