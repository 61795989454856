import styleText_AeDevNote from '../../../scss/ae-components/elements/_dev-note.scss';
const styleElement_AeDevNote = document.createElement('style');
styleElement_AeDevNote.appendChild(document.createTextNode(styleText_AeDevNote));

/* 
  AE Dev Note Template
 */
const template_AeDevNote = document.createElement('template');

template_AeDevNote.innerHTML = styleElement_AeDevNote.outerHTML + `
<div class="panel">
  <div class="panel-heading">
    <h4 class="panel-title">
      <a class="ae-panel-btn collapsed" data-toggle="collapse" href="#note-">
        <slot name="note-title"></slot>
        <span class="caret"></span>
      </a>
    </h4>
  </div>
  <div class="panel-collapse collapse" id="note-">
    <div class="panel-body">
      <slot name="note-body"></slot>
    </div>
  </div>
</div>
`;


/* 
  AE Dev Note Class
 */
class AeDevNote extends HTMLElement {

  constructor() {
    super();
    // console.log("Hello from AeDevNote!"); 

    this._idPrefix = "note-";
    this._root = this.appendChild(template_AeDevNote.content.cloneNode(true));

    this.$button = this.querySelector(".ae-panel-btn");
    this.$note = this.querySelector(".panel-collapse");
  }
    
  connectedCallback() {

    this.setId(this);
    this.fillSlots(this);

  }

  setId(elem) {

    let id = elem.generateUniqueId(elem, this._idPrefix);

    // set @href
    this.$button.setAttribute("href", this.$button.getAttribute("href") + id);
    
    // set @id
    this.$note.setAttribute("id", this.$note.getAttribute("id") + id);
  
  }

  generateUniqueId(elem, prefix) {

    let length = 8;
    let newId = parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""));
    
    if (document.getElementById(prefix + newId)) {
      elem.generateUniqueId(elem, prefix);
    } else {
      return newId;
    }
  
  }

  fillSlots(elem) {
  
    // get slot elements in HTML template 
    let slots = elem.getElementsByTagName("slot");

    // iterate over slot elements and fill with data, if present
    for (let i = 0; i < slots.length; i++) {
      let slotName = slots[i].getAttribute('name');
      slots[i].innerHTML = elem.querySelectorAll('[data-slot="' + slotName + '"]')[0].innerHTML;
    }
  
  }
  
}
    
customElements.define('ae-dev-note', AeDevNote);