import styleText_AeShowcase from '../../../scss/ae-components/elements/_showcase.scss';
const styleElement_AeShowcase = document.createElement('style');
styleElement_AeShowcase.appendChild(document.createTextNode(styleText_AeShowcase));


/* 
  AE Showcase Template
*/
const template_AeShowcase = document.createElement('template');

template_AeShowcase.innerHTML = styleElement_AeShowcase.outerHTML + `
<div class="ae-showcase showcase"> 
  <div class="container">
    <ae-logo-box> </ae-logo-box>
  </div>
</div>
`;


/* 
  AE Showcase Class
  - Demonstrates use of custom elements as a 'wrapper' to provide JS API for the element as a component and also to make use of Bootstrap available for descendants
 */
class AeShowcase extends HTMLElement {

  constructor() {
    super();
    // console.log("Hello from AeShowcase!");
    // this.addEventListener('click', () => console.log("Clicked on 'AeShowcase' component"));
  }
  
  connectedCallback() {

    this.appendChild(template_AeShowcase.content.cloneNode(true));
  
  }
    
}
  
customElements.define('ae-showcase', AeShowcase);