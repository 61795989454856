import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';

const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {
    return {
      wrapper: scope.querySelector('.wrapper'),
    }
  },

  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper">
          <div id="timeline-embed"/> 
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  },

  loadTimeline(source) {

    // Config timeline
    let timelineOptions = {
      hash_bookmark: false,
      timenav_height: 200
    }
    
    // Load data designated by last URL part
    switch(source) {
      case '02':
        window.timeline = new TL.Timeline('timeline-embed', `../docs/volume/data/events_vol_02_${_lang}.json`, timelineOptions);
        break;
      case '03':
        window.timeline = new TL.Timeline('timeline-embed', `../docs/volume/data/events_vol_03_${_lang}.json`, timelineOptions);
        break;
      case '06':
        window.timeline = new TL.Timeline('timeline-embed', `../docs/volume/data/events_vol_06_${_lang}.json`, timelineOptions);
        break;
      case 'events':
        window.timeline = new TL.Timeline('timeline-embed', `../docs/volume/data/events_edition_${_lang}.json`, timelineOptions);
        break;
      default: 
        break;
    }
  },

  reloadTimeline(dataset) {

    // Config timeline
    let timelineOptions = {
      hash_bookmark: false,
      timenav_height: 200
    }
    
    window.timeline = new TL.Timeline('timeline-embed', dataset, timelineOptions);

  }
}