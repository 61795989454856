import l10nData from './l10n.js';

export default class AeI18n {
 
  constructor() {
    this._l10nData = l10nData;
  }

  translate(key, lang) {
    return this._l10nData[lang][key];
  }

  getLang() {
    const params = (new URL(document.location)).searchParams;
    const lang = params.get("lang") ? params.get("lang") : "de";
    return lang;
  }

}