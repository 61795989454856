import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';


export default class AeTextviewerHeader extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeTextviewerHeader.connectedCallback() ...");

    // Create unique id for this component
    this._componentId = "component-" + parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(10).toString().replace(".", ""));

    // Store lang parameter
    this._lang = aeI18n.getLang();

    // Prepare properties object
    const props = {
      layout: this.getAttribute('layout'),
      guidelinesModalId: "modal-" + this._componentId + "--guidelines",
      citationModalId: "modal-" + this._componentId + "--citation",
      views: this.views,
    };

    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', Template.html(props));
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    this.addEventListener('change', (evt) => {this._select(evt);});
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['edrem-resource', 'layout', 'text-resource', 'view'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeTextviewerHeader.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'edrem-resource':
        if (this._dom && this.edremResource) {
          this._initHyperlinkToEdrem(this.edremResource);
        }
        break;
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
      case 'text-resource':
        if (this._dom) {
          this._initTextviewerHeader();
        }
        break;
      case 'view':
        if (this._dom) {
          this._content = this._render(this.layout);
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set edremResource(val) {
    this.setAttribute("edrem-resource", val);
  }

  get edremResource() {
    return this.getAttribute("edrem-resource");
  }

  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set mode(val) {
    this.setAttribute("mode", val);
  }

  get mode() {
    return this.getAttribute("mode");
  }

  set textResource(val) {
    this.setAttribute("text-resource", val);
  }

  get textResource() {
    return this.getAttribute("text-resource");
  }

  set textVersion(val) {
    this.setAttribute("text-version", val);
  }

  get textVersion() {
    return this.getAttribute("text-version");
  }

  set view(val) {
    this.setAttribute("view", val);
  }

  get view() {
    return this.getAttribute("view");
  }

  set views(val) {
    this.setAttribute("views", val);
  }

  get views() {
    return this.getAttribute("views");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeTextviewerHeader._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      if (this.querySelector('style')) {
        this.querySelector('style').remove();
      }
      
      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
      this.insertAdjacentHTML('afterbegin', '<style>' + Template.css({layout: layout}) + '</style>');
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);


    // --- Initialize ---

    // Initialize View Form
    this._dom.selectView.value = this.view;

    // Initialize Textviewer Header
    this._initTextviewerHeader();


    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }

  
  _select(evt) {
    
    console.log("(DEMO) AeTextviewerHeader._select() ...");

    // Get origin 
    switch(evt.target.getAttribute("class")) {
      case 'ae-textviewer-header__controls-view__view__select':
        this._selectView();
        break;
      case 'ae-textviewer-header__text__select':
        this._selectTextVersion();
        break;
      default:
        break;
    }
  }


  _selectView() {
    
    console.log("(DEMO) AeTextviewerHeader._selectView() ...");

    // Set property to new value
    this.setAttribute("view", this._dom.selectView.value);

    // Create custom event
    let ce = new CustomEvent("textviewer.view.change", {
      detail: {
        view: this._dom.selectView.value,
        viewer: this.closest("ae-textviewer"),
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);
  }


  _selectTextVersion() {
    
    console.log("(DEMO) AeTextviewerHeader._selectTextVersion() ...");

    // Get text resource ID
    const textResourceId = this._dom.selectTextVersion.value.substring(0, this._dom.selectTextVersion.value.indexOf("_"));

    // Get text version ID
    const textVersionId = this._dom.selectTextVersion.value.split("_").pop();

    // Set properties to new values
    this.setAttribute("text-resource", textResourceId);
    this.setAttribute("text-version", textVersionId);

    // Create custom event
    let ce = new CustomEvent("textviewer.text-version.change", {
      detail: {
        textResource: textResourceId,
        textVersion: textVersionId,
        viewer: this.closest("ae-textviewer"),
      }
    });

    // Dispatch custom event
    eventBusStd.fire(ce);
  }


  _initTextviewerHeader() {

    console.log("(DEMO) AeTextviewerHeader._initTextviewerHeader() ...");

    // Init text selection menu
    if (this.layout === "standalone") {
      this._initTextSelectionMenu(this.textResource);
    }

    // Init Hyperlink to Editorial Remark
    if (this.textResource && this.edremResource && this.layout !== "preview") {
      this._initHyperlinkToEdrem(this.edremResource);
    }
    
    // Init Citation Recommendation
    if (this.textResource && this.layout !== "preview") {
      this._initCitationRecommendation(this.textResource);
    }

    // Init Guidelines
    if (this.view && this.layout !== "preview") {
      this._initGuidelines(this.view);
    }
  }


  _initTextSelectionMenu(textResource) {

    console.log("(DEMO) AeTextviewerHeader._initTextSelectionMenu() ...");
    
    // --- Request text index ---

    let requestURL = new URL(window.location.origin + window.location.pathname + "/~query/modules/ae/query/getTextVersionIndex.xq");

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        // console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
        
        // Parse XQuery result as JSON
        this._textIndex = JSON.parse(xhr.response);

        // Render dropdown menu items
        Template.renderTextSelectionMenu(this, this._textIndex);

        // Set current value
        this._dom.selectTextVersion.value = `${this.textResource}_${this.textVersion}`;
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
  }


  _initHyperlinkToEdrem(edremResource) {

    console.log("(DEMO) AeTextviewerHeader._initHyperlinkToEdrem() ...");

    // Make icon available
    for (let i = 0; i < this._dom.edremLinks.length; i++) {
      this._dom.edremLinks[i].classList.remove("not-available");
    }

    
    // --- Build canonical URL ---

    const requestURL = new URL(window.location.origin + window.location.pathname + "/~query/modules/ae/query/getUrl.xq");
    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        // console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
        
        console.log("(DEMO) AeTextviewerHeader._initHyperlinkToEdrem(): xhr.response = " + xhr.response);

        // Store response in variable
        this._edremHref = xhr.response + edremResource + "?lang=" + this._lang;

        // Spread accross templates
        for (let i = 0; i < this._dom.edremLinks.length; i++) {
          this._dom.edremLinks[i].setAttribute("href", this._edremHref)
        }
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
  }


  _initCitationRecommendation(textResource) {

    console.log("(DEMO) AeTextviewerHeader._initCitationRecommendation(): textResource = " + textResource);

    const modalId = "modal-" + this._componentId + "--citation";

    // --- Request citation data ---
    
    let requestURL = new URL(window.location.origin + window.location.pathname + "/~query/modules/ae/query/getCitationDataForAeId.xq");
    requestURL.searchParams.set('ae-id', textResource);
    requestURL.searchParams.set('lang', this._lang);

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        // console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response   
        
        console.log("(DEMO) AeTextviewerHeader._initCitationRecommendation(): xhr.response = ");
        console.log(JSON.parse(xhr.response));

        // Parse XQuery result as JSON
        this._citationData = JSON.parse(xhr.response);

        // Render citation recommendation modal
        this.insertAdjacentHTML('beforebegin', Template.renderCitationRecommendation(this.parentElement, this._citationData.data, modalId));
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
  }


  _initGuidelines(view) {

    console.log("(DEMO) AeTextviewerHeader._initGuidelines() ...");

    const modalId = "modal-" + this._componentId + "--guidelines";

    // Check if modal was already rendered
    this._guidelines = this._guidelines ? this._guidelines : false;
    console.log("(DEBUG) AeTextviewerHeader._initGuidelines(): this._guidelines = " + this._guidelines);
    let modalGuidelinesIsRendered = (this.parentElement.querySelector('#' + modalId)) ? true : false;
    let modalGuidelinesCurrentView = (modalGuidelinesIsRendered) 
      ? this.parentElement.querySelector('#' + modalId).getAttribute("data-view") : null;

    // Render?
    if (!(this._guidelines)) {
      // Send requests for guidelines docs, collect results, then render
      // Promise.all waits until all jobs are resolved
      Promise.all([
        this._requestData(window.location.origin + window.location.pathname + "/~app/docs/about/guidelines/diplomatic_" + this._lang + ".xml"),
        this._requestData(window.location.origin + window.location.pathname + "/~app/docs/about/guidelines/constituted_" + this._lang + ".xml"),
      ]).then(([dataDipl, dataCons]) => {
        this._renderGuidelines(dataDipl, dataCons, view, modalId);
      });

      this._guidelines = true;
    }

    // Set view?
    if (modalGuidelinesIsRendered && modalGuidelinesCurrentView != view) {
      let modal = this.parentElement.querySelector('#' + modalId);
      modal.setAttribute("data-view", view);

      // Depending on view, show active tab
      switch (view) {
        case "dipl":
          // Tab
          modal.querySelector(".nav-tab__item--cons").classList.remove("active", "in"); 
          modal.querySelector(".nav-tab__item--dipl").classList.remove("active", "in");  
          modal.querySelector(".nav-tab__item--dipl").classList.add("active", "in");
          // Content
          modal.querySelector(".tab-pane__div--cons").classList.remove("active", "in"); 
          modal.querySelector(".tab-pane__div--dipl").classList.remove("active", "in");  
          modal.querySelector(".tab-pane__div--dipl").classList.add("active", "in");
          break;
        case "cons":
          // Tab
          modal.querySelector(".nav-tab__item--dipl").classList.remove("active", "in"); 
          modal.querySelector(".nav-tab__item--cons").classList.remove("active", "in");  
          modal.querySelector(".nav-tab__item--cons").classList.add("active", "in"); 
          // Content
          modal.querySelector(".tab-pane__div--dipl").classList.remove("active", "in"); 
          modal.querySelector(".tab-pane__div--cons").classList.remove("active", "in");  
          modal.querySelector(".tab-pane__div--cons").classList.add("active", "in");
          break;
        default:
          break;
      }
    }

  }


  _renderGuidelines(dataDipl, dataCons, view, modalId) {

    console.log("(DEMO) AeTextviewerHeader._renderGuidelines() ...");

    // Place modal at the end of Textviewer component
    // (modal contains several Textviewers itself and therefor interferes with 
    // this parent Textviewer component communicating to its header and content)
    this.parentElement.insertAdjacentHTML('beforeend', Template.renderGuidelines(dataDipl, dataCons, view, modalId));
  }


  _requestData(url) {

    console.log("(DEMO) AeTextviewerHeader._requestData(url) for 'url' = " + url + "...");

    return new Promise(function(resolve, reject) {
      
      // --- Set up XHR ---
      
      let requestURL = new URL(url);

      let xhr = new XMLHttpRequest();
      xhr.open("GET", requestURL, true);
      xhr.send();

      xhr.onload = () => {
        if (xhr.status != 200) { 
          // Analyze HTTP status of the response
          console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
        } else { 
          // Show the result
          // console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response   
        
          // DEMO/DEBUG MSGS
          // console.log("(DEMO) AeTextviewerHeader._requestData(): xhr.response = ");
          // console.log(xhr.response);

          // Return xhr.response
          resolve(xhr.response);
        }
      };

      xhr.onerror = function() {
        reject("Request failed");
      };
    });
  }

}


// Define custom element (avoid re-initialization)
if (!customElements.get("ae-textviewer-header")) {
  customElements.define("ae-textviewer-header", AeTextviewerHeader);
}