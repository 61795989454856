/* 
 * Feed with dummy data in console:
 * 
 * document.querySelector('demo-dropdown').options = {
 *   option1: {label: 'Option 1'},
 *   option2: {label: 'Option 2'}
 * }
 * 
 * 
 * Add listener for custom event:
 * 
 * document.querySelector('demo-dropdown').addEventListener('on-change', event => console.log(event.detail))
 * 
 * 
 * (derived from: robinwieruch.de/web-components-tutorial )
 */
const template = document.createElement('template');

/* note:
 *   :host - sets global style for custom element
 */
template.innerHTML = `
  <style>
    :host {
      --test: red;
      font-family: sans-serif;
    }

    .dropdown {
      padding: 3px 8px 8px;
    }

    .label {
      display: block;
      margin-bottom: 5px;
      color: #000000;
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
    }

    .dropdown-list-container {
      position: relative;
    }

    .dropdown.open .dropdown-list {
      display: flex;
      flex-direction: column;
    }

    .dropdown-list {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      display: none;
      max-height: 192px;
      overflow-y: auto;
      margin: 4px 0 0;
      padding: 0;
      background-color: #ffffff;
      border: 1px solid #a1a1a1;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 0 rgba(161, 161, 161, 0.4);
      list-style: none;
    }

    .dropdown-list li {
      display: flex;
      align-items: center;
      margin: 4px 0;
      padding: 0 7px;
      font-size: 16px;
      height: 40px;
      cursor: pointer;
    }

    .dropdown-list li:hover {
      background-color: #eeeeee;
    }

    .dropdown-list li.selected {
      font-weight: 600;
      color: var(--test);
    }
  </style>

  <div class="dropdown">
    <span class="label">Label</span>

    <demo-button as-atom="true">Content</demo-button>

    <div class="dropdown-list-container">
      <ul class="dropdown-list"></ul>
    </div>
  </div>
`;


class DemoDropdown extends HTMLElement {

  constructor() {
    super();

    this._sR = this.attachShadow({mode: 'open'});
    this._sR.appendChild(template.content.cloneNode(true));

    this.open = false;

    this.$label = this._sR.querySelector('.label');
    this.$button = this._sR.querySelector('demo-button');
    this.$dropdown = this._sR.querySelector('.dropdown');
    this.$dropdownList = this._sR.querySelector('.dropdown-list');

    this.$button.addEventListener(
      'on-click',
      this.toggleOpen.bind(this)
    );
  }

  toggleOpen(event) {
    this.open = !this.open;

    this.open
      ? this.$dropdown.classList.add('open')
      : this.$dropdown.classList.remove('open');
  }

  static get observedAttributes() {
    return ['label', 'option', 'options'];
  }

  get label() {
    return this.getAttribute('label');
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get option() {
    return this.getAttribute('option');
  }

  set option(value) {
    this.setAttribute('option', value);
  }

  get options() {
    return JSON.parse(this.getAttribute('options'));
  }

  set options(value) {
    this.setAttribute('options', JSON.stringify(value));
  }

  attributeChangedCallback(name, oldVal, newVal) {
    this.render();
  }

  render() {
    this.$label.innerHTML = this.label;

    this.$button.setAttribute('label', 'Select Option');
    // FE: AeButton has setter for label:
    /* this.$button.label = "Select Option"; */

    if (this.option) {
      this.$button.label = this.options[this.option].label
    };

    this.$dropdownList.innerHTML = '';

    Object.keys(this.options || {}).forEach(key => {
      let option = this.options[key];
      let $option = document.createElement('li');
      $option.innerHTML = option.label;

      if (this.option && this.option === key) {
        $option.classList.add('selected');
      }

      $option.addEventListener('click', () => {
        this.option = key;

        this.toggleOpen();

        this.dispatchEvent(
          new CustomEvent('on-change', {detail: key})
        );

        this.render();
      });

      this.$dropdownList.appendChild($option);
    });
  }
}


customElements.define('demo-dropdown', DemoDropdown);