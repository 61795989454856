import styleText from './_style.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {
    return {
      wrapper: scope.querySelector('[data-wrapper]'),
    }
  },

  html(p) {
    return `
      <template data-layout="default">
        <div data-wrapper="data-wrapper" id="${p.n}">
          <span class="label">${p.label}</span>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  }
}