import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeTextviewer / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      header: scope.querySelector('.ae-textviewer-apparatus-header'),
      header__title: scope.querySelector('#ae-textviewer-apparatus-header__title'),
      header__btnPrev__icon: scope.querySelector('#ae-textviewer-apparatus-header__prev__icon'),
      header__btnNext__icon: scope.querySelector('#ae-textviewer-apparatus-header__next__icon'),
      header__btnPrev: scope.querySelector('#ae-textviewer-apparatus-header__prev'),
      header__btnNext: scope.querySelector('#ae-textviewer-apparatus-header__next'),
      header__btnGoto: scope.querySelector('#ae-textviewer-apparatus-header__goto'),
      header__btnClose: scope.querySelector('#ae-textviewer-apparatus-header__close'),
      header__btnRemove: scope.querySelector('#ae-textviewer-apparatus-header__remove'),
      content: scope.querySelector('.ae-textviewer-apparatus-content')
    }
  },

  // Templates
  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper ae-textviewer-apparatus">
          <div class="ae-textviewer-apparatus-header" layout="default">
            <div id="ae-textviewer-apparatus-header__prev" class="ae-textviewer-apparatus-header__prev" title="${aeI18n.translate("component.textviewer-apparatus.tooltip.prev", _lang)}">
              <span id="ae-textviewer-apparatus-header__prev__icon" class="tool-icon"><span class="fas fa-angle-left"></span></span>
            </div>
            <div id="ae-textviewer-apparatus-header__title" class="ae-textviewer-apparatus-header__title"> </div>
            <div id="ae-textviewer-apparatus-header__next" class="ae-textviewer-apparatus-header__next" title="${aeI18n.translate("component.textviewer-apparatus.tooltip.next", _lang)}">
              <span id="ae-textviewer-apparatus-header__next__icon" class="tool-icon"><span class="fas fa-angle-right"></span></span>
            </div>
            <div id="ae-textviewer-apparatus-header__goto" class="ae-textviewer-apparatus-header__goto" title="${aeI18n.translate("component.textviewer-apparatus.tooltip.goto", _lang)}">
              <span class="tool-icon"><span class="fas fa-eye"></span></span>
            </div>
            <div id="ae-textviewer-apparatus-header__remove" class="ae-textviewer-apparatus-header__remove" title="${aeI18n.translate("component.textviewer-apparatus.tooltip.remove", _lang)}">
              <span class="tool-icon"><span class="fas fa-trash-alt"></span></span>
            </div>
            <div class="ae-textviewer-apparatus-header__gap">
              
            </div>
            <div id="ae-textviewer-apparatus-header__close" class="ae-textviewer-apparatus-header__close" title="${aeI18n.translate("component.textviewer-apparatus.tooltip.close", _lang)}">
              <span class="tool-icon"><span class="fas fa-times"></span></span>
            </div>
          </div>
          <div class="ae-textviewer-apparatus-content">
          
          </div>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  }
}