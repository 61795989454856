import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();


export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeIndexEntry / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      content: scope.querySelector('.ae-index-entry__content'),
      content_name: scope.querySelector('.ae-index-entry__content__name'),
      content_altNames: scope.querySelector('#ae-index-entry__content__alt-names'),
      content_desc: scope.querySelector('.ae-index-entry__content__desc'),
      content_rels: scope.querySelector('.ae-index-entry__content__relations'),
      content_links: scope.querySelector('.ae-index-entry__content__links'),
      content_editionRefs: scope.querySelector('.ae-index-entry__content__edition-refs'),
    }
  },

  // Templates
  html(p) {
    return `
      <template data-layout="page">
        <div class="wrapper">
          <div class="ae-index-entry__content">
            <div id="ae-index-entry__content__alt-names">
            </div>
            <div class="ae-index-entry__content__relations">
              <h4>${aeI18n.translate("page.index.entry.related-persons", _lang)}</h4>
              <div class="rels"></div>
            </div>
            <div class="ae-index-entry__content__links">
              <h4>${aeI18n.translate("page.index.entry.normdata-refs", _lang)}</h4>
              <div class="external"></div>
              <!-- <div data-template="ae-index_entry:getAuthorityReferences"/> -->
            </div>
            <div class="ae-index-entry__content__edition-refs">
              <!-- <div data-template="ae-index_entry:getEditionReferences"/> -->
            </div>
          </div>
        </div>
      </template>
      <template data-layout="popover">
        <div class="wrapper">
          <div class="ae-index-entry__content">
            <div class="ae-index-entry__content__name"> </div>
            <div class="ae-index-entry__content__desc"> </div>
            <div class="ae-index-entry__content__links">
              <div class="internal"><a href="">${aeI18n.translate("page.index.popover.navigate-to-index-entry", _lang)}</a></div>
              <div class="external"></div>
            </div>
          </div>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  },

  // Not in use, but keep - might come in handy later 
  resolveRole(role, mode) {
    let result = '';

    switch (role) {
      case 'dynasty':
        result = aeI18n.translate("ae.term.entity.persongrp-dynasty", _lang);
        break;
      case 'family':
        result = aeI18n.translate("ae.term.entity.persongrp-family", _lang);
        break;
      case 'siblings':
        result = aeI18n.translate("ae.term.entity.persongrp-siblings", _lang);
        break;
      case 'spouses':
        result = aeI18n.translate("ae.term.entity.persongrp-spouses", _lang);
        break;
      case 'fictitious':
        result = aeI18n.translate("ae.term.entity.person-fictitious", _lang);
        break;
      case 'mythological':
        result = aeI18n.translate("ae.term.entity.person-mythological", _lang);
        break;
      case 'biblical':
        result = aeI18n.translate("ae.term.entity.person-biblical", _lang);
        break;
      case 'deity':
        result = aeI18n.translate("ae.term.entity.person-deity", _lang);
        break;
      case 'publisher':
        result = aeI18n.translate("ae.term.entity.org-publisher", _lang);
        break;
      case undefined:
        result = "";
          break;
      default:
        result = "Error: Role not recognized. Please check index entry.";
    }

    if (mode === 'uppercase') {
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }

    return result;
  }
}