import styleText from './_style.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AeTextviewer / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      header: scope.querySelector('.ae-textviewer-header'),
      content: scope.querySelector('.ae-textviewer-content')
    }
  },

  // Templates
  html(p) {
    return `
      <template data-layout="standalone">
        <div class="wrapper">
          <ae-textviewer-header class="ae-textviewer-header" layout="standalone" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-header>
          <ae-textviewer-content class="ae-textviewer-content" mode="${p.mode}" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-content>
        </div>
      </template>
      <template data-layout="embedded">
        <div class="wrapper">
          <ae-textviewer-header class="ae-textviewer-header" layout="default" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-header>
          <ae-textviewer-content class="ae-textviewer-content" mode="${p.mode}" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-content>
        </div>
      </template>
      <template data-layout="preview">
        <div class="wrapper">
          <ae-textviewer-header class="ae-textviewer-header" layout="preview" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-header>
          <ae-textviewer-content class="ae-textviewer-content" mode="${p.mode}" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-content>
        </div>
      </template>
      <template data-layout="headless">
        <div class="wrapper">
          <ae-textviewer-content class="ae-textviewer-content" mode="${p.mode}" text-resource="${p.textResource}" text-version="${p.textVersion}" views="${p.views}" view="${p.view}"> </ae-textviewer-content>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  }
}