import styleText from './_style.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    console.log("(DEMO) AePopover / Template.mapDOM() ...");

    return {
      wrapper: scope.querySelector('.wrapper'),
      tooltip: scope.querySelector('.ae-popover__container'),
      content: scope.querySelector('.ae-popover__container__content'),
    }
  },

  // Templates
  html(p) {
    return `
      <template data-layout="index">
        <div class="wrapper">
          <div id="${p.popoverId}" class="ae-popover__container" role="tooltip">
            <div class="ae-popover__container__content">
              <ae-index-entry class="ae-index-entry" layout="popover" type="${p.type.substring(6)}" ref="${p.content.substring(1) ? p.content.substring(1) : ''}" loaded=""></ae-index-entry>
            </div>
            <div class="ae-popover__container__arrow" data-popper-arrow="data-popper-arrow"/>
          </div>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  }

}

/* DEPRECATED IF POPOVER IS NOT CHILD OF DATA-PROVIDING-NODE
 * 
 * When layout="index", index entry ID should be provided by the closest 
 * .tei_name or .tei_rs ancestor.
 * 
 * @param c    The component 
 * 
 * @return    A string value that is an index entry ID or an empty string.
 */
function _getIndexReference(c) {
  const id = c.closest('.tei_name,.tei_rs').getAttribute("data-ref").substring(1);
  const result = (id) ? id : ""; 
  return result;
}