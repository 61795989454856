import Template from './_template.js';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';


export default class AeIndexEntry extends HTMLElement {

  constructor() {
    super();

    console.log("(DEMO) AeIndexEntry.constructor() ...");

    this._sR = this.attachShadow({mode: 'open'});

    // Store lang parameter
    this._lang = aeI18n.getLang();

    // Prepare properties object
    const props = {
      layout: this.layout,
      type: this.type,
      ref: this.ref,
      loaded: this.loaded,
    };
    
    // Load and append style and templates
    const style = document.createElement('style');
    style.innerHTML = Template.css(props);
    this._sR.appendChild(style);
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component (renders template, data is loaded into rendered template)
    this._content = this._render(this.layout);
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['loaded'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeIndexEntry.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'loaded':
        if (this._dom && (newVal === "loaded")) {
          if (this.ref) {
            this._loadData(this.ref);
          } else {
            console.log("(WARNING) AeIndexEntry.attributeChangedCallback(): Tried to load data, but there is no data reference given.");
          }
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set type(val) {
    this.setAttribute("type", val);
  }

  get type() {
    return this.getAttribute("type");
  }

  set ref(val) {
    this.setAttribute("ref", val);
  }

  get ref() {
    return this.getAttribute("ref");
  }

  set loaded(val) {
    this.setAttribute("loaded", val);
  }

  get loaded() {
    return this.getAttribute("loaded");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeIndexEntry._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "page";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      // Render component DOM
      this._sR.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this._sR);

    // Initialize view
    return this._initializeView();
  }


  _loadData(personId) {

    console.log("(DEMO) AeIndexEntry._loadData() ...");


    // --- Load content ---

    if (personId) {

      this._getDataForId(personId, (d, k, labelObj, rels, l) => {
        this._fillTemplate(d, k, labelObj, rels, l);
      });

    }
  };


  _initializeView() {

    console.log("(DEMO) AeIndexEntry._initializeView() ...");

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _fillTemplate(data, type, labelObj, rels, layout) {

    // Variables
    // TODO: Outsource: if dev environment, add "/exist/apps/sade-Arendt"
    const path = (window.location.pathname.startsWith("/exist")) ? window.location.pathname.substring(0, 23) : "";
    const pathIndexEntry = window.location.origin + path + "/index/persons/person?id=";
    

    // --- Layout "popover" only ---

    if (layout === "popover") {
      
      // Regular name
      switch(this._lang) {
        case 'de':
          this._dom.content_name.innerHTML = labelObj['reg']['de'];
          break;
        case 'en':
          this._dom.content_name.innerHTML = labelObj['reg']['de'];
          break;
        default:
          this._dom.content_name.innerHTML = labelObj['generic'];
      }
      
      // Desc
      // FE, 2023-10-26, NOTE: shortDesc is declared deprecated;
      //   keep the code however - I have a feeling we might need it again.
      // this._getShortDescForId(data["xml:id"], this._lang);

      // Link to index
      this._dom.content_links.querySelector(".internal a").setAttribute("href", `${pathIndexEntry}${data["xml:id"]}&lang=${this._lang}`);
    }

    
    // --- Layout "page" only ---

    if (layout === "page") {

      // Alternative names
      if (Array.isArray(data["persName"])) {
        const altNameObjs = data["persName"].filter(item => item.type === "alt");
        if (altNameObjs.length) {
          this._appendAltNames(this._dom.content_altNames, altNameObjs);
        }
      }

      // Authority references
      if (data["idno"]) {
        this._dom.content_links.setAttribute("data-empty", "false");
      }

      // Relations
      this._getRelations(rels, pathIndexEntry);
      
      // Edition references
      this._getEditionReferencesForId(data["xml:id"]);
    }


    // --- All layouts ---

    // References to authority data
    for (let i in data["idno"]) {
      const div_idno = document.createElement("div");
      const linkText = 
        data["idno"][i]["type"].toUpperCase()
        + " (" + data["idno"][i]["#text"].split('/').pop() + ")";
      
      div_idno.innerHTML = `<a href="${data["idno"][i]["#text"]}" target="_blank">${linkText}</a>`;
      this._dom.content_links.querySelector(".external").appendChild(div_idno);
    }
  }


  _appendAltNames(container, altNameObjs) {

    // Header
    const header = document.createElement("h4");
    header.innerHTML = aeI18n.translate("component.index-entry.header.alt-names", this._lang);
    container.appendChild(header);

    // Content
    for (let i = 0; i < altNameObjs.length; i++) {
      const div_altName = document.createElement("div");
      const altName = altNameObjs[i]["ae:label"];
      div_altName.innerHTML = altName;
      container.appendChild(div_altName);
    }
  }


  _getRelations(rels, pathIndexEntry) {

    if (rels) {
      // Show relations section
      this._dom.content_rels.setAttribute("data-empty", "false");

      // Iterate through relation entries
      for (let rel in rels) {
        const div_rel = document.createElement("div");
        this._getDataForId(rels[rel].target, (d) => {
          const relText = d["ae:label"];
          div_rel.innerHTML = `<a href="${pathIndexEntry + rels[rel].target}">${relText}</a>`;
          this._dom.content_rels.querySelector(".rels").appendChild(div_rel);
        });
      }
    }
  }


  _getDataForId(id, callback) {
    
    let requestURL = new URL(window.location.origin + window.location.pathname + "/~api/index/persons/" + id);

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
          
        // Parse XQuery result as JSON
        this._data = JSON.parse(xhr.response);

        // Get key of entry node (its name may vary)
        const firstKey = Object.keys(this._data)[0];
          
        // Trigger callback function
        callback(this._data[firstKey], firstKey, this._data["label"], this._data["relation"], this.layout);
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
  }

  
  _getEditionReferencesForId(id) {

    let requestURL = new URL(window.location.origin + window.location.pathname + "/~query/modules/ae/query/getEditionReferencesForId.xq");
    requestURL.searchParams.set('id', id);
    requestURL.searchParams.set('lang', this._lang);

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        

        // Render response
        this._dom.content_editionRefs.insertAdjacentHTML('afterbegin', xhr.response);
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
  }


  _getShortDescForId(id, lang) {

    let requestURL = new URL(window.location.origin + window.location.pathname + "/~query/modules/ae/query/getShortDescForId.xq");
    requestURL.searchParams.set('id', id);
    requestURL.searchParams.set('lang', lang);

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        

        // Render response
        this._dom.content_desc.insertAdjacentHTML('afterbegin', xhr.response);
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-index-entry")) {
  customElements.define("ae-index-entry", AeIndexEntry);
}