import styleText from './_style.scss';
import {bookmarkLib} from '../ae-bookmark-manager/bookmark-manager-config.js';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();


export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {

    return {
      wrapper: scope.querySelector('.wrapper'),
      tbody: scope.querySelector('tbody'),
      rows: scope.querySelectorAll('tbody tr'),
      removeBookmarkBtns: scope.querySelectorAll('tbody tr .ae-library-table__entry__remove')
    }
  },

  // Templates 
  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper">
          <table class="table">
            <thead>
              <tr>
                <th>${aeI18n.translate("component.library-table.header.title", _lang)}</th>
                <th>${aeI18n.translate("component.library-table.header.volume", _lang)}</th>
                <th>${aeI18n.translate("component.library-table.header.timestamp", _lang)}</th>
                <th>${aeI18n.translate("component.library-table.header.options", _lang)}</th>
              </tr>
            </thead>
            <tbody>
              ${_buildRows()}
            </tbody>
          </table>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  },

  rebuildRows(indexTextversions, dom) {

    let rows = ``;
    let bookmarksArr = bookmarkLib.getBookmarks();
  
    // Iterate through bookmarks and append items as table rows
    for (let i = 0; i < bookmarksArr.length; i++) {
  
      const tsDisplay = new Date(bookmarksArr[i].timestamp);
      
      const rowEl = `<tr data-textresource="${bookmarksArr[i].textResource}" data-textversion="${bookmarksArr[i].textVersion}">
        <td data-textresource="data-textresource"></td>
        <td data-volume="data-volume"></td>
        <td><span>${tsDisplay.toLocaleString()}</span></td>
        <td>
          <div class="ae-library-table__entry__remove" title="${aeI18n.translate("component.library-table.tooltip.remove", _lang)}">
            <span class="tool-icon"><span class="fas fa-trash-alt"></span></span>
          </div>
        </td>
      </tr>`;
  
      rows = rows + rowEl;
    };

    // Append rows
    dom.tbody.innerHTML = rows;
    
    // Update mapDOM?
    this.renderRowContents(indexTextversions, dom.tbody.querySelectorAll('tr'));
  },

  renderRowContents(data, rows) {

    // Variables
    // TODO: Outsource: if dev environment, add "/exist/apps/sade-Arendt"
    const path = (window.location.pathname.startsWith("/exist")) ? window.location.pathname.substring(0, 23) : "";
    const pathCanonical = window.location.origin + path + "/";
    const textVersionsArr = data.texts.item;

    for (let i = 0; i < rows.length; i++) {

      const textResourceId = rows[i].getAttribute("data-textresource");
      const textVersionSuffix = rows[i].getAttribute("data-textversion")
      const textVersionId = `${rows[i].getAttribute("data-textresource")}${textVersionSuffix ? "_" + textVersionSuffix : ""}`;
      const textVersionData = textVersionsArr.find(e => e["ae:text-version-id"] === textVersionId);

      const title = textVersionData["title"].find(e => e["xml:lang"] === _lang)["#text"];
      const volume = textVersionData["ae:vol"];
      const resourceUrl = `${pathCanonical}${textResourceId}${textVersionSuffix ? "?text-version=" + textVersionSuffix : ""}`;

      rows[i].querySelector('[data-textresource]').innerHTML = `<span><a href="${resourceUrl}">${title}</a></span>`;
      rows[i].querySelector('[data-volume]').innerHTML = `<span>${Number(volume)}</span>`;
    }

  }
}


function _buildRows() {

  let rows = ``;
  let bookmarksArr = bookmarkLib.getBookmarks();

  // Iterate through bookmarks and append items as table rows
  for (let i = 0; i < bookmarksArr.length; i++) {

    const tsDisplay = new Date(bookmarksArr[i].timestamp);
    
    const rowEl = `<tr data-textresource="${bookmarksArr[i].textResource}" data-textversion="${bookmarksArr[i].textVersion}">
      <td data-textresource="data-textresource"></td>
      <td data-volume="data-volume"></td>
      <td><span>${tsDisplay.toLocaleString()}</span></td>
      <td>
        <div class="ae-library-table__entry__remove" title="${aeI18n.translate("component.library-table.tooltip.remove", _lang)}">
          <span class="tool-icon"><span class="fas fa-trash-alt"></span></span>
        </div>
      </td>
    </tr>`;

    rows = rows + rowEl;
  };

  return rows;
}