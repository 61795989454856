import Template from './_template.js';


export default class AeButtonSearch extends HTMLElement {

  connectedCallback() {

    const props = {
      property: this.getAttribute('property'),
    };

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Add event listeners
    this._dom.toggleBtn.addEventListener('click', (evt) => {this._toggle(evt);});
    this._dom.input.addEventListener('blur', (evt) => {this._blur(evt);});
  }


  // *** Methods ***

  _toggle(evt) {
    evt.preventDefault();
    if (this._dom.input.classList.contains("show")) {
      this._dom.input.classList.remove("show");
      this._dom.form.classList.remove("focus");
    } else {
      this._dom.input.classList.add("show");
      this._dom.input.focus();
      this._dom.form.classList.add("focus");
    }
  }

  _blur(evt) {
    this._dom.input.classList.remove("show");
    this._dom.form.classList.remove("focus");
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-button-search")) {
  customElements.define("ae-button-search", AeButtonSearch);
}