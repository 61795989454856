import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeIndexListingItem extends HTMLElement {

  connectedCallback() {

    console.log("(DEMO) AeIndexListingItem.connectedCallback() ...");

    // Prepare properties object
    const props = {
      layout: this.getAttribute('layout'),
      idref: this.getAttribute('idref'),
      label: this.getAttribute('label'),
      predicates: this.getAttribute('predicates'),
      gnd: this.getAttribute('gnd')
    };
    
    // Load and append style
    this.insertAdjacentHTML('afterbegin', Template.html(props));
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');

    // Render component
    this._content = this._render(this.layout);
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    console.log("(DEMO) AeIndexListingItem.attributeChangedCallback(): '" + name + "' attribute changed from '" + oldVal + "' to '" + newVal + "'");
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }

  set idref(val) {
    this.setAttribute("idref", val);
  }

  get idref() {
    return this.getAttribute("idref");
  }

  set label(val) {
    this.setAttribute("label", val);
  }

  get label() {
    return this.getAttribute("label");
  }

  set predicates(val) {
    this.setAttribute("predicates", val);
  }

  get predicates() {
    return this.getAttribute("predicates");
  }

  set gnd(val) {
    this.setAttribute("gnd", val);
  }

  get gnd() {
    return this.getAttribute("gnd");
  }


  // *** Methods ***

  _render(layout) {

    console.log("(DEMO) AeIndexListingItem._render() ...");

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      if (this.querySelector('style')) {
        this.querySelector('style').remove();
      }

      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
      this.insertAdjacentHTML('afterbegin', '<style>' + Template.css({layout: layout}) + '</style>');
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize component size and view
    return this._initializeView();
  }


  _initializeView() {

    console.log("(DEMO) AeIndexListingItem._initializeView() ...");

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-index-listing-item")) {
  customElements.define("ae-index-listing-item", AeIndexListingItem);
}