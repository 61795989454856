import styleText from './_style.scss';

export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {
    return {
      wrapper: scope.querySelector('[data-wrapper]'),
    }
  },

  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper">
          <span>Slot Content:</span>
          <slot name="field-a">A</slot>
        </div>
      </template>
      <template data-layout="btn">
        <div data-wrapper="data-wrapper" id="${p.n}" class="auswahl ${p.state} ${p.type}">
          <span class="label">${p.label}</span>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
    `;
  }
}