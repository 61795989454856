import styleText from './_style.scss';
import {aeI18n} from '../../../ae-i18n/i18n-config.js';
const _lang = aeI18n.getLang();


export default {
  render(props) {
    return `
      ${this.css(props)}
      ${this.html(props)}
    `;
  },

  mapDOM(scope) {
    return {
      wrapper: scope.querySelector('.wrapper'),
    }
  },

  // Templates
  html(p) {
    return `
      <template data-layout="default">
        <div class="wrapper">
          <div class="ae-logo-box__typo">
            <span class="ae-logo-box__typo__part--ha">Hannah Arendt</span><br/>
            <span class="ae-logo-box__typo__part--kg">${aeI18n.translate("component.logo-box.titel-a1", _lang)}<br/> ${aeI18n.translate("component.logo-box.titel-a2", _lang)}</span>
          </div>
        </div>
      </template>
    `;
  },

  css(p) {
    return `
      ${styleText}
      `;
  },
}