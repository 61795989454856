import Template from './_template.js';
import {eventBusStd} from '../../../ae-bus/bus-config.js';


export default class AeLibraryTable extends HTMLElement {

  connectedCallback() {

    // Prepare properties object
    const props = {
      layout: this.layout,
    };
    
    // Load and append style and templates
    this.insertAdjacentHTML('afterbegin', '<style>' + Template.css(props) + '</style>');
    this.insertAdjacentHTML('beforeend', Template.html(props));

    // Render component
    this._content = this._render(this.layout);

    // Add event listeners
    eventBusStd.register('library.bookmark.change', (evt) => this._onToggleBookmark(evt));
  }


  // *** Attributes ***

  static get observedAttributes() {
    return ['layout'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    switch(name) {
      case 'layout':
        if (this._dom) {
          this._content = this._render(newVal);
        }
        this._stateChange(name, newVal);
        break;
    }
  }


  // *** Getters / Setters ***
  
  set layout(val) {
    this.setAttribute("layout", val);
  }

  get layout() {
    return this.getAttribute("layout");
  }


  // *** Methods ***

  _loadData() {

    // --- Load index of text versions ---

    let requestURL = new URL(window.location.origin + window.location.pathname + "/~api/index/textversions/");

    let xhr = new XMLHttpRequest();
    xhr.open("GET", requestURL, true);
    xhr.send();

    xhr.onload = () => {
      if (xhr.status != 200) { 
        // Analyze HTTP status of the response
        console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { 
        // Show the result
        console.log(`Done, got ${xhr.response.length} bytes`); // response is the server response        
        
        // Parse XQuery result as JSON
        this._indexTextversions = JSON.parse(xhr.response);

        // Render row content
        Template.renderRowContents(this._indexTextversions, this._dom.rows);
      }
    };

    xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        // console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
        // console.log(`Received ${event.loaded} bytes`); // No content length
      }
    };

    xhr.onerror = function() {
      console.log("Request failed");
    };
 
  };


  _render(layout) {

    // Default template (@data-layout="default")
    if (!layout) {
      layout = "default";
    }

    // Check if template is available
    if (this.querySelector('template[data-layout=' + layout + ']')) {
      // If component is populated, clear content first
      if (this._content) {
        this._content.remove();
      }
      if (this.querySelector('style')) {
        this.querySelector('style').remove();
      }

      // Render component DOM
      this.appendChild(this.querySelector('template[data-layout=' + layout + ']').content.cloneNode(true));
      this.insertAdjacentHTML('afterbegin', '<style>' + Template.css({layout: layout}) + '</style>');
    }

    // Save component DOM references
    this._dom = Template.mapDOM(this);

    // Initialize view
    return this._initializeView();
  }


  _initializeView() {

    // Load data if it has not been loaded yet
    if (!this._indexTextversions) {
      this._loadData();
    } else {
      Template.rebuildRows(this._indexTextversions, this._dom);
      // Update component DOM references
      this._dom = Template.mapDOM(this);
    }


    // --- Initialize Index Listing and its Subcomponents ---

    // Initialize Subcomponents
    this._initRemoveBookmarkBtns();

    // Return 'wrapper' DOM object
    return this._dom.wrapper;
  }


  _initRemoveBookmarkBtns() {

    // Iterate over remove buttons
    for (let i = 0; i < this._dom.removeBookmarkBtns.length; i++) {

      // Add event listener
      this._dom.removeBookmarkBtns[i].addEventListener("click", (evt) => {

        const rowEl = evt.target.closest("tr");
        
        // Create custom event
        let ce = new CustomEvent("library.bookmark.change", {
          detail: {
            textResource: rowEl.getAttribute("data-textresource"),
            textVersion: rowEl.getAttribute("data-textversion"),
            action: "remove"
          }
        });

        // Dispatch custom event
        eventBusStd.fire(ce);
      });

    }

  }


  _stateChange(property, value) {

    // Create custom event
    let ce = new CustomEvent("component_state_change", {
      bubbles: true,
      detail: {
        changedProperty: property,
        newValue: value,
        component: this,
      }
    });

    // Dispatch custom event
    this.dispatchEvent(ce);
    
  }


  _onToggleBookmark() {
    this._content = this._render(this.layout);
  }

}

// Define custom element (avoid re-initialization)
if (!customElements.get("ae-library-table")) {
  customElements.define("ae-library-table", AeLibraryTable);
}