export default 
{
  "en": {
    "ae.title.name-part": "Hannah Arendt",
    "ae.title.edition-part": "Complete Works. Critical Edition",

    "ae.volume.02.title--long": "Rahel Varnhagen. Lebensgeschichte einer deutschen Jüdin aus der Romantik / The Life of a Jewess / The Life of a Jewish Woman",
    "ae.volume.02.title--medium": "Rahel Varnhagen. Lebensgeschichte einer deutschen Jüdin / The Life of a Jewess / The Life of a Jewish Woman",
    "ae.volume.02.title--short": "Rahel Varnhagen",
    "ae.volume.03.title--long": "Sechs Essays / Die verborgene Tradition",
    "ae.volume.03.title--short": "Sechs Essays",
    "ae.volume.06.title--long": "The Modern Challenge to Tradition: Fragmente eines Buchs",
    "ae.volume.06.title--short": "The Modern Challenge to Tradition",

    "ae.term.edition.volume": "volume",
    "ae.term.edition.volume--short": "Vol.",
    "ae.term.edition.citation.ed--short": "Ed. by",
    "ae.term.edition.citation.webportal-version": "Webportal version",
    "ae.term.edition.citation.of-date": "of",

    "ae.term.entity.person-fictitious": "fictitious person",
    "ae.term.entity.person-mythological": "mythological figure",
    "ae.term.entity.person-biblical": "biblical figure",
    "ae.term.entity.person-deity": "deity",
    "ae.term.entity.persongrp": "person group",
    "ae.term.entity.persongrp-dynasty": "dynasty",
    "ae.term.entity.persongrp-family": "family",
    "ae.term.entity.persongrp-siblings": "siblings",
    "ae.term.entity.persongrp-spouses": "spouses",
    "ae.term.entity.org": "organization",
    "ae.term.entity.org-publisher": "publisher",

    "page.index.entry.normdata-refs": "Authority references",
    "page.index.entry.related-persons": "Related persons",
    "page.index.popover.navigate-to-index-entry": "Go to index entry",
    "page.search.result.show-metadata": "Show metadata...",
    "page.search.result.hide-metadata": "Hide metadata...",
    "page.search.result.show-more-hits": "Show more hits in this document...",
    "page.search.result.show-less-hits": "Show less hits in this document...",
    "page.search.tools.filter.show-more": "Show more...",
    "page.search.tools.filter.show-less": "Show less...",
    "page.versions.doc-list.show-list": "Show list...",
    "page.versions.doc-list.hide-list": "Hide list...",

    "component.index-entry.header.alt-names": "Alternative Names",
    "component.textviewer-apparatus.tooltip.prev": "Previous editorial note in selection",
    "component.textviewer-apparatus.tooltip.next": "Next editorial note in selection",
    "component.textviewer-apparatus.tooltip.goto": "Go to lemma",
    "component.textviewer-apparatus.tooltip.remove": "Remove editorial note from selection",
    "component.textviewer-apparatus.tooltip.close": "Close apparatus",
    "component.textviewer-header.tooltip.editorial-guidelines": "Reading aid",
    "component.textviewer-header.tooltip.citation-recommendation": "Citation recommendation",
    "component.textviewer-header.tooltip.editorial-commentary": "Editorial introduction",
    "component.textviewer-header.dropdown-view.dipl": "Diplomatic representation",
    "component.textviewer-header.dropdown-view.cons": "Constituted text",
    "component.textviewer-header.dropdown-view.facs": "Facsimiles",
    "component.textviewer-header.dropdown-view.code": "XML",
    "component.tool-item-toggle.indexes.persons-highlighting": "Highlight persons",
    "component.tool-item-toggle.indexes.works-highlighting": "Highlight works",
    "component.logo-box.titel-a1": "Complete Works",
    "component.logo-box.titel-a2": "Critical Edition",

    "ui.label.all": "all",
    
  },
  "de": {
    "ae.title.name-part": "Hannah Arendt",
    "ae.title.edition-part": "Kritische Gesamtausgabe",

    "ae.volume.02.title--long": "Rahel Varnhagen. Lebensgeschichte einer deutschen Jüdin aus der Romantik / The Life of a Jewess / The Life of a Jewish Woman",
    "ae.volume.02.title--medium": "Rahel Varnhagen. Lebensgeschichte einer deutschen Jüdin / The Life of a Jewess / The Life of a Jewish Woman",
    "ae.volume.02.title--short": "Rahel Varnhagen",
    "ae.volume.03.title--long": "Sechs Essays / Die verborgene Tradition",
    "ae.volume.03.title--short": "Sechs Essays",
    "ae.volume.06.title--long": "The Modern Challenge to Tradition: Fragmente eines Buchs",
    "ae.volume.06.title--short": "The Modern Challenge to Tradition",

    "ae.term.edition.volume": "Band",
    "ae.term.edition.volume--short": "Band",
    "ae.term.edition.citation.ed--short": "Hrsg. v.",
    "ae.term.edition.citation.webportal-version": "Version",
    "ae.term.edition.citation.of-date": "des Webportals vom",

    "ae.term.entity.person-fictitious": "fiktive Person",
    "ae.term.entity.person-mythological": "mythologische Figur",
    "ae.term.entity.person-biblical": "biblische Figur",
    "ae.term.entity.person-deity": "Gottheit",
    "ae.term.entity.persongrp": "Personengruppe",
    "ae.term.entity.persongrp-dynasty": "Dynastie",
    "ae.term.entity.persongrp-family": "Familie",
    "ae.term.entity.persongrp-siblings": "Geschwister",
    "ae.term.entity.persongrp-spouses": "Eheleute",
    "ae.term.entity.org": "Organisation",
    "ae.term.entity.org-publisher": "Verlag",

    "page.index.entry.normdata-refs": "Referenzen auf Normdatensätze",
    "page.index.entry.related-persons": "Assoziierte Personen",
    "page.index.popover.navigate-to-index-entry": "Zum Registereintrag",
    "page.search.result.show-metadata": "Metadaten anzeigen...",
    "page.search.result.hide-metadata": "Metadaten ausblenden...",
    "page.search.result.show-more-hits": "Weitere Treffer im Dokument anzeigen...",
    "page.search.result.show-less-hits": "Weniger Treffer im Dokument anzeigen...",
    "page.search.tools.filter.show-more": "Mehr anzeigen...",
    "page.search.tools.filter.show-less": "Weniger anzeigen...",
    "page.versions.doc-list.show-list": "Liste anzeigen...",
    "page.versions.doc-list.hide-list": "Liste ausblenden...",

    "component.index-entry.header.alt-names": "Alternative Namen",
    "component.textviewer-apparatus.tooltip.prev": "Vorheriger Stellenkommentar in der Auswahl",
    "component.textviewer-apparatus.tooltip.next": "Nächster Stellenkommentar in der Auswahl",
    "component.textviewer-apparatus.tooltip.goto": "Gehe zu Lemma",
    "component.textviewer-apparatus.tooltip.remove": "Stellenkommentar von der Auswahl entfernen",
    "component.textviewer-apparatus.tooltip.close": "Kommentarapparat schließen",
    "component.textviewer-header.tooltip.editorial-guidelines": "Lesehilfe",
    "component.textviewer-header.tooltip.citation-recommendation": "Zitierempfehlung",
    "component.textviewer-header.tooltip.editorial-commentary": "Editorische Einleitung",
    "component.textviewer-header.dropdown-view.dipl": "Diplomatische Ansicht",
    "component.textviewer-header.dropdown-view.cons": "Konstituierter Text",
    "component.textviewer-header.dropdown-view.facs": "Faksimiles",
    "component.textviewer-header.dropdown-view.code": "XML",
    "component.tool-item-toggle.indexes.persons-highlighting": "Erwähnte Personen hervorheben",
    "component.tool-item-toggle.indexes.works-highlighting": "Erwähnte Werke hervorheben",
    "component.logo-box.titel-a1": "Kritische",
    "component.logo-box.titel-a2": "Gesamtausgabe",

    "ui.label.all": "alle",
    
  }
}