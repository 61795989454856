/* 
  Customized extension of built-in element
 */
class AeTextFeature extends HTMLSpanElement {

  constructor() {
    super();
    this.addEventListener('click', () => console.log("Hello from 'ae-text-feature'. Descendant text nodes: " + this.textContent));
  }

}

 
/* 
  Autonomous custom element
 */
class AeAutonomousFeature extends HTMLElement {

  constructor() {
    super();
    // element created
  }

  render() {

    let content = this.getAttribute('content');

    this.innerHTML = content;
  
  }

  connectedCallback() {
    // browser calls this method when the element is added to the document
    // (can be called many times if an element is repeatedly added/removed)

    const shadow = this.attachShadow({mode: 'open'});
    shadow.innerHTML = 
    `<span class="content">
      Content: ${this.getAttribute('content')}
    </span>`;

    if (!this.rendered) {
      this.render();
      this.rendered = true;
    }
  }

  disconnectedCallback() {
    // browser calls this method when the element is removed from the document
    // (can be called many times if an element is repeatedly added/removed)
  }

  static get observedAttributes() {
    /* array of attribute names to monitor for changes */
    return ['content'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    // called when one of attributes listed above is modified
    this.render();
  }

}



customElements.define('ae-text-feature', AeTextFeature, {extends: 'span'});
customElements.define('ae-autonomous-feature', AeAutonomousFeature);
